import React from "react";

import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'

export const BtnAdicionar = ({onClick, type, text, title}) => (
    <Col>
        <ButtonToolbar>
            <Button variant="success" onClick={onClick} type={type} title={title} className="btn-adicionar" block>
                <FontAwesomeIcon icon={faPlusCircle} /> {text}
            </Button>
        </ButtonToolbar>
    </Col>
);
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import * as _ from 'lodash';

import { toast } from "react-toastify";

import { Input, Select } from "../../../components/Inputs";
import { mapObjectToSelectObject } from '../../../components/Inputs/Select';
import {
  BtnSalvar,
  BtnVoltar,
  BtnAdicionarModal,
  BtnAdicionar,
  BtnEncerrar
} from "../../../components/Button";

import Popup from "../../../components/Popup";

import api from "../../../services/api";

import EmbalagensManter from "../../EmbalagensManter";
import DestinatarioExternoManter from "../../DestinatarioExternoManter";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressCard,
  faPuzzlePiece,
  faBox,
  faMap
} from "@fortawesome/free-solid-svg-icons";
import { ConvertSelectObjectToValue } from "../../../utils/convertSelectObjectToValue";

export default function ManterPreListagem({
  objetos,
  setObjetos,
  submitObjetos,
  submitEncerrar,
  setRemetente,
  setObjenvio,
  remetente,
  objenvio,
  prelistagem_id,
  auth,
  setAuth
}) {
  const {
    register,
    watch,
    getValues,
    setValue,
    handleSubmit,
    control,
    reset
  } = useForm();

  const opcoesTipoRemetente = [
    { name: "Funcionário", value: 1 },
    { name: "Lotação", value: 2 }
  ]

  const tipo_destinatario = watch("tipo_destinatario", "");
  const tipo_remetente = watch("tipo_remetente", "");
  const tipoEmbalagem = watch("tipo_embalagem", '');
  const destinatario = watch("destinatario");
  const embalagem_selecionada = watch("embalagem");

  const [show, setShow] = useState(false);
  const [showemb, setShowemb] = useState(false);
  const [showdest, setShowdest] = useState(false);
  const [showSelectEmbalagens, setShowSelectEmbalagens] = useState(false);
  const [infoEmbalagem, setInfoEmbalagem] = useState({});

  const [tiposEmbalagens, setTiposEmbalagens] = useState([]);

  const [embalagens, setEmbalagens] = useState([]);
  const [opcoesEmbalagens, setOpcoesEmbalagens] = useState([]);

  const [destinatarios, setDestinatarios] = useState([]);
  const [opcoesDestinatarios, setOpcoesDestinatarios] = useState([]);

  const [lotacoes, setLotacoes] = useState([]);
  const [opcoesLotacoes, setOpcoesLotacoes] = useState([]);

  const [funcionarios, setFuncionarios] = useState([]);
  const [opcoesFuncionarios, setOpcoesFuncionarios] = useState([]);
  const [servpost, setServpost] = useState([]);

  const [opcoesEnderecos, setOpcoesEnderecos] = useState([]);

  const [servicosAdicionais, setServicosAdicionais] = useState([]);

  const [servicos, setServicos] = useState([]);

  const tipoServico = watch("tipo_servico");

  useEffect(() => {
    async function loadServicosAdicionais() {
      try {
        const response = await api.get("objeto-servico-adc");
        setServicosAdicionais(response.data.data);
        setServicos([
          response.data.data.find(
            serv => serv.descricao == "Aviso de Recebimento"
          )
        ]);
      } catch (err) {
        console.log(err);
      }
    }

    loadServicosAdicionais();
  }, []);

  useEffect(() => {
    async function loadTipoEmbalagens() {
      try {
        const response = await api.get("embalagem-tipo");

        const data = response.data.data;
        // Array dos códigos para os tipos de serviços que são Cartas
        const servicosCarta = ["10065", "12556", "10138"];

        const tipoEmbalagensData = [];

        if (servicosCarta.includes(_.get(tipoServico, 'value', '').trim())) {
          data.forEach(tipoembalagem => {
            if (tipoembalagem.label === "Envelope") {
              tipoEmbalagensData.push(tipoembalagem);
            }
          });

          setTiposEmbalagens(tipoEmbalagensData);

          return;
        }

        setTiposEmbalagens(data);
      } catch (err) {
        console.log(err);
      }
    }

    loadTipoEmbalagens();
  }, [tipoServico]);

  async function loadservicosLotacao(data) {
    try {
      const response = await api.get(`/cartao-postagem/lotacao-servicos/${data}`);
	     console.log("Aqui embaixo vai o response.data de lotacao");
	            console.log(response.data);
	setServpost([response.data.data.find(serv => _.trim(serv.value) === '03220')]);
	    console.log("Aqui embaixo vai o servpost de lotacao");
	console.log(servpost);
    } catch (err) {
      console.log(err);
    }
  }

  async function loadservicosFuncionarios(data) {
    try {
      const response = await api.get(
        `/cartao-postagem/funcionario-servicos/${data}`
      );
      setServpost([response.data.data.find(serv => _.trim(serv.value) === '04669')]);
	    console.log("Aqui embaixo vai o servpost de servidor");         
	    console.log(servpost);
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    if (prelistagem_id) {
      populateRemetenteSelects(remetente);
      parseInt(remetente["lotacao"]) &&
        loadservicosLotacao(remetente["lotacao"]);
      parseInt(remetente["funcionario"]) &&
        loadservicosFuncionarios(remetente["funcionario"]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remetente, funcionarios, lotacoes]);

  useEffect(() => {
    async function loadEmbalagens() {
      try {
        const value = _.get(tipoEmbalagem, 'value', '');
        if (value) {
          setOpcoesEmbalagens([]);
          setValue('embalagem', '');

          const response = await api.get(
            `embalagem?embalagem=${value}&situacao=Ativo`
          );
          const opcoes_embalagens = _.get(response, 'data.data', []).map(embalagem => ({
            name: embalagem.descricao,
            value: embalagem.id
          }));

          if (_.size(opcoes_embalagens) === 0) {
            opcoes_embalagens.push({
              name: 'Nenhuma embalagem para este tipo.',
              value: ''
            });
          } else {
            opcoes_embalagens.unshift({ name: '<<Selecione>>', value: '' });
          }

          setInfoEmbalagem({});
          setOpcoesEmbalagens(opcoes_embalagens);
          setShowSelectEmbalagens(true);
        }

      } catch (err) {
        console.log(err);
      }
    }

    loadEmbalagens();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showemb, tipoEmbalagem]);

  useEffect(() => {
    async function loadEmbalagensValue() {
      const value = _.get(embalagem_selecionada, 'value', '');
      if (value) {
        const response = await api.get(`embalagem/${value}`);
        const { peso, comprimento, altura, largura } = _.get(response, 'data.data', {});
        if (peso || comprimento || altura || largura) {
          setInfoEmbalagem({
            peso,
            comprimento,
            altura,
            largura
          })
        }
      }
    }
    loadEmbalagensValue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [embalagem_selecionada, embalagens, setValue]);

  useEffect(() => {
    async function loadDestinatarios() {
      try {
        if (_.get(tipo_destinatario, 'value', 0) === 3) {
          const response = await api.get(`destinatario-externo?situacao=Ativo`);
          setDestinatarios(response.data.data);
        }
      } catch (err) {
        console.log(err);
      }
    }

    loadDestinatarios();
  }, [showdest, tipo_destinatario]);

  useEffect(() => {
    async function fetchData() {
      try {
        if (prelistagem_id) {
          const responsepreslistagem = await api.get(
            `postagem/${prelistagem_id}`
          );
          let prelistagem = responsepreslistagem.data.data;

          setRemetente({
            tipo_remetente: prelistagem["id_funcionario"] === 0 ? 2 : 1,
            lotacao: prelistagem["id_lotacao_setor"] || "",
            funcionario: prelistagem["id_funcionario"] || "",
            id_cartao_postagem_vinculo:
              prelistagem["id_cartao_postagem_vinculo"]
          });

          setObjetos(
            prelistagem.objeto_postagem.map(obj => {
              return {
                altura: infoEmbalagem["altura"],
                comprimento: infoEmbalagem["altura"],
                largura: infoEmbalagem["largura"],
                peso: infoEmbalagem["peso"],

                nota_fiscal: obj["nota_fiscal"],
                descricao: obj["observacoes"],
                destinatario:
                  (obj["id_lotacao_setor"] && obj["lotacao"]["DESCRICAO"]) ||
                  (obj["id_destinatario_externo_endereco"] &&
                    obj["destinatario_externo_endereco"]["destinatario_externo"]["nome"]) ||
                  (obj["id_funcionario"] && obj["funcionario"]["NOME"]),
                endereco:
                  (obj["id_lotacao_setor"] && obj["lotacao"]["ENDERECO"]) ||
                  (obj["id_destinatario_externo_endereco"] &&
                    obj["destinatario_externo_endereco"]["logradouro"]) ||
                  (obj["id_funcionario"] && obj["funcionario"]["ENDERECO"]),
                servico: obj["objeto_servico_vinculo_adc"],
                tipo_embalagem: obj["embalagem"]["embalagem_tipo"]["descricao"]
              };
            })
          );
          setObjenvio(
            prelistagem.objeto_postagem.map(obj => {
              return {
                id: obj["id"],
                id_lotacao_setor: obj["id_lotacao_setor"] || "",
                id_funcionario: obj["id_funcionario"] || "",
                id_destinatario_externo: obj["id_destinatario_externo"] || "",
                valor_cobrar: obj["valor_cobrar"],
                valor_declarado: obj["valor_declarado"],
                tipo_servico_postagem: obj["tipo_servico_postagem"],
                observacoes: obj["observacoes"],
                id_embalagem: obj["id_embalagem"],
                nota_fiscal: obj["nota_fiscal"],
                servico: obj["objeto_servico_adc"].map(serv => {
                  return { id_objeto_servico_adc: serv.id };
                })
              };
            })
          );
        }
      } catch (err) {
        console.log(err);
      }
    }

    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prelistagem_id]);

  useEffect(() => {
    const opcoes_destinatarios = destinatarios.map(destinatario => {
      return { name: destinatario.nome, value: destinatario.id };
    });

    setOpcoesDestinatarios(opcoes_destinatarios);
  }, [destinatarios]);

  useEffect(() => {
    async function loadLotacoes() {
      try {
        const responseLotacoes = await api.get(
          "cartao-postagem-vinculo/lotacoes"
        );

        setLotacoes(responseLotacoes.data.data);

        const responseLotacoesDest = await api.get("lotacao");
        const opcoes_lotacoes = responseLotacoesDest.data.data.map(lotacao => {
          return {
            name: lotacao.DESCRICAO,
            value: lotacao.ID_LOTACAO_SETOR,
            endereco: lotacao.ENDERECO
          };
        });

        setOpcoesLotacoes(opcoes_lotacoes);
      } catch (err) {
        console.log(err);
      }
    }

    loadLotacoes();
  }, []);

  useEffect(() => {
    async function loadFuncionarios() {
      try {
        const response = await api.get("cartao-postagem-vinculo/funcionarios");
        const responsefunc = await api.get("funcionario");

        setOpcoesFuncionarios(responsefunc.data.data);
        setFuncionarios(response.data.data);
      } catch (err) {
        console.log(err);
      }
    }
    reset();
    loadFuncionarios();
  }, [reset]);

  useEffect(() => {
    async function loadEnderecos() {
      try {
        const response = await api.get(`destinatario-externo/${_.get(destinatario, 'value')}`);
        const opcoes_enderecos = response.data.data.destinatario_externo_endereco.map(
          endereco => {
            return {
              name: `${endereco.logradouro} - ${endereco.bairro}`,
              value: endereco.id
            };
          }
        );

        if (!opcoes_enderecos.length > 0) {
          opcoes_enderecos.push({
            name: "Este destinatário não possui nenhum endereço.",
            value: ""
          });
        }

        setOpcoesEnderecos(opcoes_enderecos);
      } catch (err) {
        console.log(err);
      }
    }

    destinatario && _.get(tipo_destinatario, 'value') === 3 && loadEnderecos();
  }, [destinatario, tipo_destinatario]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function populateRemetenteSelects(data) {
    const selectedTipoRemetente = opcoesTipoRemetente.find(x => x.value === data["tipo_remetente"]);
    if (selectedTipoRemetente) setValue("tipo_remetente", mapObjectToSelectObject(selectedTipoRemetente));

    const selectedFuncionario = _.find(funcionarios, funcionario => funcionario.value === data['funcionario']);
    if (selectedFuncionario) setValue('funcionario', mapObjectToSelectObject(selectedFuncionario));

    const selectedLotacao = _.find(lotacoes, lotacao => lotacao.value === data["lotacao"]);
    if (selectedLotacao) setValue('lotacao', mapObjectToSelectObject(selectedLotacao));
  }

  async function handleVerification() {
    const { login, senha } = getValues();
    try {
      console.log("Aqui embaixo vai o servpost antes de autenticar");
	    console.log(servpost);

	const response = await api.post(`/loginPostagem`, {
        usuario: login,
        senha: senha,
        cartao_postagem: servpost[0]["codigo"]
      });
      setAuth(true);
      toast.success("Credenciais Verificadas!");
    } catch (err) {
      toast.error("Credenciais Inválidas");
	 console.log(err);
    }
    setShow(false);
  }

  function checkRequireds(data) {

    const valid = [
      'tipo_remetente', 'tipo_servico', 'tipo_embalagem',
      'embalagem', 'tipo_destinatario'
    ].every(field => !!data[field]);

    if (!valid) return valid;

    if (_.get(data, 'tipo_remetente', 0) === 1 && !_.get(data, 'funcionario'))
      return false;

    if (_.get(data, 'tipo_remetente', 0) === 2 && !_.get(data, 'lotacao'))
      return false;

    if (_.get(data, 'destinatario', 0) === 3 && !_.get(data, 'destinatario_endereco'))
      return false;

    return true;
  }

  function onSubmit(data) {
    let selectedDestinatario;

    const fieldsForConvert = [
      'embalagem', 'lotacao', 'tipo_destinatario',
      'tipo_embalagem', 'tipo_remetente',
      'tipo_servico', 'destinatario', 'destinatario_endereco',
      'funcionario'
    ];

    Object.keys(data).forEach(key => {
      if (fieldsForConvert.indexOf(key) > -1)
        data[key] = ConvertSelectObjectToValue(key, data);
    });

    if (!checkRequireds(data)) {
      toast.warn('Preencha todos os campos obrigatórios');
      return;
    }

    switch (_.get(tipo_destinatario, 'value', 0)) {
      case 1:
        selectedDestinatario = opcoesLotacoes.find(
          lotacao => lotacao.value === _.get(destinatario, 'value', 0)
        );
        data["id_lotacao_setor"] = selectedDestinatario.value;
        break;
      case 2:
        selectedDestinatario = opcoesFuncionarios.find(
          funcionario => funcionario.value === _.get(destinatario, 'value', 0)
        );

        data["id_funcionario"] = selectedDestinatario.value;

        break;
      case 3:
        selectedDestinatario = destinatarios.find(
          dest => dest.id === _.get(destinatario, 'value', 0)
        );
        const destinatario_endereco = watch("destinatario_endereco");
        data["id_destinatario_externo_endereco"] = destinatario_endereco.value;

        selectedDestinatario = {
          value: selectedDestinatario.id,
          name: selectedDestinatario.nome,
          endereco: opcoesEnderecos.find(end => end.value === data["id_destinatario_externo_endereco"])['name']
        };
        break;
      default:
        selectedDestinatario = "Destinatário vazio";
    }

    const selectedTipoEmbalagem = tiposEmbalagens.find(
      tipo => tipo.value === _.get(tipoEmbalagem, 'value')
    );

    setRemetente({
      tipo_remetente: data['tipo_remetente'],
      lotacao: data['lotacao'],
      funcionario: data['funcionario'],
      id_cartao_postagem_vinculo: servpost[0]['id_cartao_postagem_vinculo']
    });

    const embalagem = opcoesEmbalagens.find(
      emb => emb.value == data["embalagem"]
    );
    setObjetos([
      ...objetos,
      {
        altura: infoEmbalagem["altura"],
        comprimento: infoEmbalagem["comprimento"],
        largura: infoEmbalagem["largura"],
        peso: infoEmbalagem["peso"],
        embalagem: data['embalagem'],
        descricao: data["observacoes"],
        nota_fiscal: data["nota_fiscal"],
        tipo_destinatario: data['tipo_destinatario'],
        valor_cobrar: data["valor_cobrar"],
        valor_declarado: data["valor_declarado"],
        tipo_embalagem: _.get(selectedTipoEmbalagem, 'name'),
        endereco: _.get(selectedDestinatario, 'endereco'),
        destinatario: _.get(selectedDestinatario, 'name'),
        servico: servicos,
        index: objetos.length + 1
      }
    ]);
    setObjenvio([
      ...objenvio,
      {
        id_lotacao_setor: data["id_lotacao_setor"] || "",
        id_funcionario: data["id_funcionario"] || "",
        id_destinatario_externo: data["id_destinatario_externo_endereco"] || "",
        valor_cobrar: data["valor_cobrar"],
        valor_declarado: data["valor_declarado"],
        tipo_servico_postagem: data['tipo_servico'],
        observacoes: data["observacoes"],
        id_embalagem: data['embalagem'],
        nota_fiscal: data["nota_fiscal"],
        servico: servicos.map(serv => {
          return { id_objeto_servico_adc: serv.id };
        })
      }
    ]);
    reset({
      tipo_remetente: data['tipo_remetente'],
      funcionario: data["funcionario"],
      lotacao: data['lotacao'],
      tipo_embalagem: ''
    });
    populateRemetenteSelects(data);
    setShowSelectEmbalagens(false);
    setInfoEmbalagem({});
  }

  return (
    <>
      <div className="mt-4">
        <Row>
          <Col md={6}>
            <Card.Title>Manter Postagem</Card.Title>
            <Card.Subtitle className="text-muted" style={{ fontSize: 11 }}>
              * Campos obrigatórios
            </Card.Subtitle>
          </Col>
          <Col md={6}>
            <BtnSalvar onClick={submitObjetos} />
            <BtnEncerrar
              onClick={() => {
                if (auth) {
                  submitEncerrar();
                } else {
                  toast.error("O cartão deve ser verificado!");
                }
              }}
            />
            <BtnVoltar />
          </Col>
        </Row>
      </div>
      <Form onSubmit={handleSubmit(onSubmit)} className="manter-postagem">
        <hr />
        <Row>
          <Col md={12}>
            <Card className="informacoes-gerais">
              <Card.Header>
                <Col md={8}>
                  <Card.Title>
                    <FontAwesomeIcon icon={faAddressCard} /> Remetente
                  </Card.Title>
                </Col>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Select
                    md={4}
                    label="Tipo Remetente*"
                    controlId="tipo_remetente"
                    control={control}
                    options={[
                      {
                        name: "<<Selecione>>",
                        value: ""
                      },
                      ...opcoesTipoRemetente
                    ]}
                  />
                  {_.get(tipo_remetente, 'value') === 1 && (
                    <Select
                      md={4}
                      label="Funcionário*"
                      controlId="funcionario"
                      control={control}
                      onChangeSelect={({ value }) => {
                        value &&
                          loadservicosFuncionarios(value);
                      }}
                      options={[
                        {
                          name: "<<Selecione>>",
                          value: ""
                        },
                        ...funcionarios
                      ]}
                    />
                  )}
                  {_.get(tipo_remetente, 'value') === 2 && (
                    <Select
                      md={4}
                      label="Lotação*"
                      controlId="lotacao"
                      onChangeSelect={({ value }) => {
                        value && loadservicosLotacao(value);
                      }}
                      control={control}
                      options={[
                        {
                          name: "<<Selecione>>",
                          value: ""
                        },
                        ...lotacoes
                      ]}
                    />
                  )}
                  {(!!_.get(watch("lotacao"), 'value') || !!_.get(watch("funcionario"), 'value')) && (
                    <BtnAdicionarModal
                      onClick={() => setShow(true)}
                      title="Validar Cartão Postagem"
                    />
                  )}
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        {auth && (
          <>
            <Row>
              <Col md={12}>
                <Card className="informacoes-gerais">
                  <Card.Header>
                    <Col md={8}>
                      <Card.Title>
                        <FontAwesomeIcon icon={faAddressCard} /> Informações
                        Gerais
                      </Card.Title>
                    </Col>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Select
                        label="Tipo Serviço*"
                        controlId="tipo_servico"
                        disabled="disabled"
                        control={control}
                        options={servpost}
                      />
                      <Input
                        md={4}
                        label="Nota Fiscal"
                        controlId="nota_fiscal"
                        placeholder="Nota Fiscal"
                        register={register}
                      />
                      <Input
                        md={2}
                        label="Valor Declarado"
                        controlId="valor_declarado"
                        placeholder="Valor Declarado"
                        register={register}
                      />
                      <Input
                        md={2}
                        label="Valor a Cobrar"
                        controlId="valor_a_cobrar"
                        placeholder="Valor a Cobrar"
                        register={register}
                      />
                    </Row>
                    <Row>
                      <Form.Group
                        controlId="descricao"
                        style={{
                          width: "100%",
                          margin: "0 15px"
                        }}
                      >
                        <Form.Label>Declaração de Conteúdo</Form.Label>
                        <Form.Control
                          name={"observacoes"}
                          ref={register}
                          as="textarea"
                          maxLength={30}
                          rows="3"
                          cols="100"
                        />
                      </Form.Group>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Card style={{ width: "100%" }}>
                  <Card.Header>
                    <Card.Title>
                      <FontAwesomeIcon icon={faPuzzlePiece} /> Serviços
                      Adicionais
                    </Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      {["checkbox"].map(type => (
                        <div key={`default-${type}`} className="mb-3">
                          {servicosAdicionais.map(servicoAdicional => (
                            <Form.Check
                              key={servicoAdicional.id}
                              type={type}
                              label={servicoAdicional.descricao}
                              defaultChecked={
                                servicoAdicional.descricao ==
                                  "Aviso de Recebimento"
                                  ? true
                                  : false
                              }
                              onChange={e => {
                                if (
                                  servicos.find(
                                    item => item == servicoAdicional
                                  )
                                ) {
                                  const filterServico = servicos.filter(
                                    obj => servicoAdicional !== obj
                                  );
                                  setServicos([...filterServico]);
                                } else {
                                  setServicos([...servicos, servicoAdicional]);
                                }
                              }}
                            />
                          ))}
                        </div>
                      ))}
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={6}>
                <Card style={{ width: "100%" }}>
                  <Card.Header>
                    <Card.Title>
                      <FontAwesomeIcon icon={faBox} /> Dimensões do objeto
                    </Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Select
                        label="Tipo Embalagem*"
                        controlId="tipo_embalagem"
                        control={control}
                        options={[
                          {
                            name: "<<Selecione>>",
                            value: ""
                          },
                          ...tiposEmbalagens
                        ]}
                      />
                    </Row>
                    {showSelectEmbalagens && (
                      <>
                        <Row>
                          <Select
                            label="Embalagem*"
                            controlId="embalagem"
                            control={control}
                            options={opcoesEmbalagens}
                          />
                          <BtnAdicionar
                            text="Nova"
                            title="Nova Embalagem"
                            onClick={() => {
                              setShowemb(true);
                            }}
                          />
                        </Row>

                        <Row>
                          <Input
                            label="Peso(g)"
                            disabled="disabled"
                            controlId="peso"
                            placeholder="Peso(g)"
                            value={infoEmbalagem.peso}
                          />
                          <Input
                            label="Comprimento(cm)"
                            disabled="disabled"
                            controlId="comprimento"
                            placeholder="Comprimento(cm)"
                            value={infoEmbalagem.comprimento}
                          />
                        </Row>
                        <Row>
                          <Input
                            label="Largura(cm)"
                            controlId="largura"
                            disabled="disabled"
                            placeholder="Largura(cm)"
                            value={infoEmbalagem.largura}
                          />
                          <Input
                            label="Altura(cm)"
                            disabled="disabled"
                            controlId="altura"
                            placeholder="Altura(cm)"
                            value={infoEmbalagem.altura}
                          />
                        </Row>
                      </>
                    )}
                  </Card.Body>
                </Card>
              </Col>
              <Col md={12}>
                <Card style={{ width: "100%" }}>
                  <Card.Header>
                    <Card.Title>
                      <FontAwesomeIcon icon={faMap} /> Destinatário objeto
                    </Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Select
                        md={3}
                        label="Destinatário*"
                        controlId="tipo_destinatario"
                        control={control}
                        options={[
                          {
                            name: "<<Selecione>>",
                            value: ""
                          },
                          {
                            name: "Lotacação/Setor",
                            value: 1
                          },
                          {
                            name: "Funcionário",
                            value: 2
                          },
                          {
                            name: "Destinatário Externo",
                            value: 3
                          }
                        ]}
                      />
                      {_.get(tipo_destinatario, 'value') === 1 && (
                        <Select
                          md={4}
                          label="Lotação/Setor*"
                          controlId="destinatario"
                          control={control}
                          options={[
                            {
                              name: "<<Selecione>>",
                              value: ""
                            },
                            ...opcoesLotacoes
                          ]}
                        />
                      )}
                      {_.get(tipo_destinatario, 'value') === 2 && (
                        <Select
                          md={4}
                          label="Funcionário*"
                          controlId="destinatario"
                          control={control}
                          options={[
                            {
                              name: "<<Selecione>>",
                              value: ""
                            },
                            ...opcoesFuncionarios
                          ]}
                        />
                      )}
                      {_.get(tipo_destinatario, 'value') === 3 && (
                        <>
                          <Select
                            md={3}
                            label="Destinatário Externo*"
                            controlId="destinatario"
                            control={control}
                            options={[
                              {
                                name: "<<Selecione>>",
                                value: ""
                              },
                              ...opcoesDestinatarios
                            ]}
                          />
                          {_.get(destinatario, 'value') && (
                            <Select
                              md={3}
                              label="Endereço*"
                              controlId="destinatario_endereco"
                              control={control}
                              options={[
                                {
                                  name: "<<Selecione>>",
                                  value: ""
                                },
                                ...opcoesEnderecos
                              ]}
                            />
                          )}
                          <Col md="3">
                            <BtnAdicionar
                              text="Novo destinatário"
                              title="Novo destinatário"
                              onClick={() => {
                                setShowdest(true);
                              }}
                            />
                          </Col>
                        </>
                      )}
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row className="justify-content-md-center text-center mb-2">
              <Col>
                <Button title="Adicionar objeto" variant="success mr-3" type="submit">
                  Adicionar objeto
                </Button>
                <Button variant="primary" title="Limpar" onClick={() => reset()}>
                  Limpar campos
                </Button>
              </Col>
            </Row>
          </>
        )}
        <Popup
          show={show}
          setShow={setShow}
          title={" Validação do Cartão de Postagem"}
          onSave={handleVerification}
        >
          <Input
            label="Login contrato Correios"
            controlId="login"
            placeholder="Login"
            register={register({ required: true })}
          />
          <Input
            label="Senha contrato Correios"
            controlId="senha"
            placeholder="Senha"
            type="password"
            register={register({ required: true })}
          />
        </Popup>
      </Form>
      <Modal
        size="xl"
        show={showemb}
        onHide={() => setShowemb(false)}
        animation={true}
        centered
      >
        <EmbalagensManter modal={true} setShowemb={setShowemb} />
      </Modal>

      <Modal
        size="xl"
        show={showdest}
        onHide={() => setShowdest(false)}
        animation={true}
        centered
      >
        <DestinatarioExternoManter modal={true} setShowdest={setShowdest} />
      </Modal>
    </>
  );
}

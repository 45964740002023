import React, { useState, useRef, useEffect } from "react";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import { PDFExport } from "@progress/kendo-react-pdf";

import api from "../../services/api";

import Declaracao from "../DocumentsLayouts/Declaracao";

export default function PrintingModal({ show, setShow, title, postagem }) {
  const [printingChoices, setPrintingChoices] = useState(["plp", "rotulo", "ars", "declaracao"]);
  const [declara, setDeclara] = useState([]);
  const inputEl = useRef(null);
  
  function handlePrintingChoice(e) {
    if (e.target.checked) {
      setPrintingChoices([...printingChoices, e.target.name]);
    } else {
      const filteredChoices = printingChoices.filter(
        choice => choice !== e.target.name
      );

      setPrintingChoices(filteredChoices);
    }
  }

  useEffect(() => {
    async function declaracaoDados() {
      const response = await api.get(`postagem/${postagem["id"]}`);
      setDeclara(response.data.data);
    }
    postagem["id"] && declaracaoDados();
  }, [show]);

  async function handlePrint() {
    printingChoices.find(tree => tree == "rotulo") &&
      api
        .get(`postagem/pdf/${postagem["id"]}?rotulo=true`, {
          responseType: "blob"
        })
        .then(response => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", "rotulo.pdf");
          document.body.appendChild(fileLink);

          fileLink.click();
        });

    printingChoices.find(tree => tree == "ars") &&
      api
        .get(`postagem/pdf/${postagem["id"]}?ars=true`, {
          responseType: "blob"
        })
        .then(response => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", "ars.pdf");
          document.body.appendChild(fileLink);

          fileLink.click();
        });

    printingChoices.find(tree => tree == "plp") &&
      api
        .get(`postagem/pdf/${postagem["id"]}?plp=true`, {
          responseType: "blob"
        })
        .then(response => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", "plp.pdf");
          document.body.appendChild(fileLink);

          fileLink.click();
        });

    if (printingChoices.find(tree => tree == "declaracao")) {
      const response = await api.get(`postagem/${postagem["id"]}`);
      setDeclara(response.data.data);
      inputEl.current.save();
    }

    setShow(false);
    setPrintingChoices(["plp", "rotulo", "ars", "declaracao"]);
  }

  return (
    <>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        animation={true}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {["checkbox"].map(type => (
            <>
              <Form.Check
                key="plp"
                type={type}
                defaultChecked={true}
                label="PLP"
                name="plp"
                onClick={e => handlePrintingChoice(e)}
              />
              <Form.Check
                key="rotulo"
                type={type}
                defaultChecked={true}
                label="Rótulo"
                name="rotulo"
                onClick={e => handlePrintingChoice(e)}
              />
              <Form.Check
                key="ars"
                type={type}
                defaultChecked={true}
                label="ARs"
                name="ars"
                onClick={e => handlePrintingChoice(e)}
              />
              <Form.Check
                key="declaracao"
                type={type}
                defaultChecked={true}
                label="Declaração de conteúdo"
                name="declaracao"
                onClick={e => handlePrintingChoice(e)}
              />
            </>
          ))}
        </Modal.Body>

        <div style={{ position: "absolute", left: "-10000px", top: 0 }}>
          <PDFExport
            forcePageBreak=".page-break"
            scale={0.6}
            fileName={"declaracao.pdf"}
            title={"Declaração Conteúdo"}
            ref={inputEl}
            paperSize="A4"
          >
            <Declaracao postagem={declara} />
          </PDFExport>
        </div>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setShow(false);
              setPrintingChoices([]);
            }}
          >
            Cancelar
          </Button>
          {printingChoices.length > 0 && (
            <Button variant="primary" onClick={handlePrint}>
              Imprimir
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}

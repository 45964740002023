import React, { useState, useEffect } from "react";

import { useSelector } from "react-redux";

import { useForm } from "react-hook-form";

import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";

import api from "../../../services/api";
import { hasAccess } from "../../../services/auth";

import { Input, Select } from "../../../components/Inputs";
import {
  BtnIncluir,
  BtnConsultar,
  BtnLimpar
} from "../../../components/Button";

export const BuscarEmbalagens = ({ onSubmit }) => {
  const permissoes = useSelector(state => state.user.permissoes);

  const [tipoEmbalagem, setTipo] = useState([]);

  const { register, handleSubmit, reset, control } = useForm();

  function handleReset() {
    reset();
    onSubmit();
  }

  useEffect(() => {
    async function fetchData() {
      try {
        const responseEmbalagemTipo = await api.get(`embalagem-tipo`);
        setTipo(responseEmbalagemTipo.data.data);
      } catch (err) {
        console.log(err);
      }
    }
    fetchData();
  }, [reset]);

  return (
    <Row>
      <Card className="card-destinatario">
        <Card.Header>
          <Col md={8}>
            <Card.Title>Consultar Embalagem</Card.Title>
          </Col>
          <Col md={4}>
            {hasAccess(
              permissoes,
              "SIGEP_EMBALAGENS",
              "ACAO_INCLUIR"
            ) && <BtnIncluir url="/embalagens/manter" />}
            <BtnLimpar onClick={() => handleReset()} />
          </Col>
        </Card.Header>
        <Card.Body className="card-body">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Input
                md={6}
                label="Descrição"
                controlId="descricao"
                placeholder="Descrição"
                register={register}
              />
              <Select
                md={3}
                label="Embalagem"
                controlId="embalagem"
                control={control}
                options={[
                  { name: "<<Selecione>>", value: "" },
                  ...tipoEmbalagem
                ]}
              />
              <Select
                md={3}
                label="Situação"
                controlId="situacao"
                control={control}
                options={[
                  { name: "<<Selecione>>", value: "" },
                  { name: "Ativo", value: "Ativo" },
                  { name: "Inativo", value: "Inativo" }
                ]}
              />
            </Row>
            <Row>
              <Input
                md={3}
                label="Comprimento (cm)"
                controlId="comprimento"
                placeholder="Comprimento (cm)"
                register={register}
              />
              <Input
                md={2}
                label="Largura (cm)"
                controlId="largura"
                placeholder="Largura"
                register={register}
              />
              <Input
                md={2}
                label="Altura (cm)"
                controlId="altura"
                placeholder="Altura"
                register={register}
              />
              <Input
                md={2}
                label="Peso (gramas)"
                controlId="peso"
                placeholder="Peso"
                register={register}
              />
              <BtnConsultar title="Consultar" />
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </Row>
  );
};

import React from "react";

import { useSelector} from 'react-redux';
import { useForm } from "react-hook-form";

import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";

import { hasAccess } from "../../../services/auth";

import { Input, Select } from "../../../components/Inputs";
import {
  BtnIncluir,
  BtnConsultar,
  BtnLimpar
} from "../../../components/Button";

function BuscarDestinatarioExterno({ onSubmit }) {
  const permissoes = useSelector(state => state.user.permissoes);

  const { register, handleSubmit, reset, control } = useForm();

  function handleReset() {
    reset();
    onSubmit();
  }

  return (
    <Row>
      <Card className="card-destinatario">
        <Card.Header>
          <Col md={8}>
            <Card.Title>Consultar Destinatário Externo</Card.Title>
          </Col>
          <Col md={4}>
            <BtnLimpar onClick={() => handleReset()} />
            {hasAccess(permissoes, "SIGEP_DESTINATARIO_EXTERNO", "ACAO_INCLUIR") && (
              <BtnIncluir url="/destinatarioexterno/manter" />
            )}
          </Col>
        </Card.Header>
        <Card.Body className="card-body">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Input
                md={6}
                label="Nome"
                controlId="nome"
                placeholder="Nome"
                register={register}
              />
              <Input
                md={6}
                label="Endereço"
                controlId="endereco"
                placeholder="Endereço"
                register={register}
              />
            </Row>
            <Row>
              <Input
                md={6}
                label="CEP"
                controlId="cep"
                placeholder="CEP"
                register={register}
              />
              <Select
                md={4}
                label="Situação"
                controlId="situacao"
                control={control}
                options={[
                  { name: "<<Selecione>>", value: "" },
                  { name: "Ativo", value: "Ativo" },
                  { name: "Inativo", value: "Inativo" }
                ]}
              />
              <BtnConsultar title="Consultar" />
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </Row>
  );
}

export default BuscarDestinatarioExterno;

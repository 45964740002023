import React, { useState } from "react";
import { toast } from "react-toastify";

import { useParams, useLocation } from "react-router-dom";

import Container from "react-bootstrap/Container";

import history from "../../services/history";
import api from "../../services/api";

import ManterDestinatarioExterno from "./ManterDestinatarioExterno";
import TabelaDestinatarioExternoEndereco from "./TabelaDestinatarioExternoEndereco";
import { ConvertSelectObjectToValue } from "../../utils/convertSelectObjectToValue";

export default function DestinatarioExternoManter({ modal, setShowdest }) {
  let { id } = useParams();
  const query = useLocation().search;

  id = modal ? false : id;
  const [endereco, setEndereco] = useState([]);

  const onSubmit = data => {
    data.endereco = endereco;
    postDestinatario(data);
  };

  const postDestinatario = async data => {
    ['tipo_pessoa', 'situacao', 'tratamento'].forEach(field =>
      data[field] = ConvertSelectObjectToValue(field, data)
    );
    id
      ? api
        .put(`/destinatario-externo/${id}`, data)
        .then(res => {
          toast.success('Atualizado o destinatário externo!');
          history.push("/destinatarioexterno");
        })
        .catch(error => {
          console.log(error);
        })
      : api
        .post(`/destinatario-externo`, data)
        .then(res => {
          toast.success('Cadastrado o destinatário externo!');
          modal ? setShowdest(false) : history.push("/destinatarioexterno");
        })
        .catch(error => {
          console.log(error);
        });
  };

  return (
    <Container>
      <ManterDestinatarioExterno
        onSubmit={onSubmit}
        endereco={endereco}
        destinatario_id={id}
        modal={modal}
      />
      <TabelaDestinatarioExternoEndereco
        title="Endereços cadastrados"
        setEndereco={setEndereco}
        endereco={endereco}
        destinatario_id={id}
      />
    </Container>
  );
}

import produce from 'immer';

const INITIAL_STATE = {
  permissoes: {},
  loading: false
};

export default function user(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@auth/SIGN_IN_SUCCESS':
      return produce(state, draft => {
        draft.user = action.payload.user;
        draft.permissoes = action.payload.permissoes;
      });
    case '@auth/SIGN_OUT':
      return produce(state, draft => {
        draft.permissoes = null;
        draft.user = null;
      });
    case '@user/UPDATE_PROFILE_REQUEST':
      return produce(state, draft => {
        draft.loading = true;
      });
    case '@user/UPDATE_PROFILE_SUCCESS':
      return produce(state, draft => {
        draft.user = action.payload.user;
        draft.permissoes = action.payload.permissoes;
        draft.loading = false;
      });
    case '@user/UPDATE_PROFILE_FAILURE':
      return produce(state, draft => {
        draft.loading = false;
      });
    default:
      return state;
  }
}

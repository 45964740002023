import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Table } from "../../../components/Table";
import { BtnDeletar } from "../../../components/Button";
import api from "../../../services/api";

export default function TabelaObjetoCadastradoPlp({
  title,
  data,
  setRemove,
  setObjetos,
  prelistagem_id
}) {
  const { reset } = useForm();

  useEffect(() => {
    async function loadObjetos() {
      try {
        const response = await api.get(`postagem/${prelistagem_id}`);
      } catch (err) {
        console.log(err);
      }
    }

    prelistagem_id && loadObjetos();
  }, [prelistagem_id, reset, setObjetos]);

  const columns = [
    {
      dataField: "index",
      text: "#",
      headerStyle: {
        width: "5%"
      },
    },
    {
      dataField: "destinatario",
      text: "Destinatário",
      sort: true,
      headerStyle: {
        width: "15%"
      },
      style: { overflowWrap: 'break-word' }
    },
    {
      dataField: "descricao",
      text: "Descrição",
      sort: true,
      headerStyle: {
        width: "15%"
      },
      style: { overflowWrap: 'break-word' }
    },
    {
      dataField: "endereco",
      text: "Endereço",
      sort: true,
      headerStyle: {
        width: "15%"
      },
      style: { overflowWrap: 'break-word' }
    },
    {
      dataField: "tipo_embalagem",
      text: "Tipo Embalagem",
      sort: true,
      headerStyle: {
        width: "10%",
        overflowWrap: 'break-word'
      },
      style: { overflowWrap: 'break-word' }
    },
    {
      dataField: "servico",
      text: "Serviço",
      sort: true,
      formatter: (cell, row) => {
        const servicos =
          row.servico &&
          row.servico.map(serv => {
            return serv.descricao;
          });
        const formattedServicos = row.servico ? servicos.join(",") : "";
        return <p className="text-break">{formattedServicos}</p>;
      },
      headerStyle: {
        width: "10%"
      },
      style: { overflowWrap: 'break-word' }
    },
    {
      dataField: "acoes",
      text: "Ação",
      headerStyle: {
        width: "5%"
      },
      formatter: (cell, row, rowIndex) => (
        <Row className="d-flex align-items-center justify-content-center">
          <BtnDeletar onClick={() => setRemove(rowIndex)} className="btn-sm" />
        </Row>
      )
    }
  ];

  const [preListagem, setPreListagem] = useState();

  return (
    <Col>
      <Table
        title={title}
        data={data}
        columns={columns}
        noDataMessage={"Nenhum objeto cadastrado no momento"}
      />
    </Col>
  );
}

import React, { useState, useEffect } from "react";

import { useSelector } from "react-redux";

import { useForm } from "react-hook-form";

import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";

import api from "../../../services/api";
import { hasAccess } from "../../../services/auth";
import * as _ from 'lodash';

import {
  BtnIncluir,
  BtnLimpar,
  BtnConsultar
} from "../../../components/Button";
import { Input, Select } from "../../../components/Inputs";

export default function BuscarPreListagem({ onSubmit }) {
  const permissoes = useSelector(state => state.user.permissoes);

  const { register, handleSubmit, reset, watch, control } = useForm();

  const [lotacoes, setLotacoes] = useState([]);
  const [funcionarios, setFuncionarios] = useState([]);

  const [destlotacoes, setDestlotacoes] = useState([]);
  const [destfuncionarios, setDestfuncionarios] = useState([]);
  const [destexternos, setDestexternos] = useState([]);

  const remetente = watch("tipo_remetente", "");
  const destinatario = watch("destinatario", "");

  useEffect(() => {
    async function loadRemetente() {
      try {
        const responsefunc = await api.get(
          "cartao-postagem-vinculo/funcionarios"
        );
        const responselot = await api.get("cartao-postagem-vinculo/lotacoes");
        setFuncionarios(responsefunc.data.data);
        setLotacoes(responselot.data.data);
      } catch (err) {
        console.log(err);
      }
    }
    loadRemetente();
  }, []);

  useEffect(() => {
    async function loadDestinatarios() {
      try {
        const responsefunc = await api.get("funcionario");
        const responselot = await api.get("lotacao");
        const responsedest = await api.get("destinatario-externo");

        setDestfuncionarios(responsefunc.data.data);
        setDestlotacoes(responselot.data.data.map((dest, index) => {
          return { name: dest.DESCRICAO, value: dest.ID_LOTACAO_SETOR }
        }));
        setDestexternos(responsedest.data.data.map((dest, index) => {
          return { name: dest.nome, value: dest.id }
        }));
      } catch (err) {
        console.log(err);
      }
    }
    loadDestinatarios();
  }, []);

  function handleReset() {
    reset();
    onSubmit();
  }
  return (
    <Row>
      <Card className="card-prelistagem">
        <Card.Header>
          <Col md={8}>
            <Card.Title>Consulta Postagem</Card.Title>
          </Col>
          <Col md={4}>
            {hasAccess(permissoes, "SIGEP_POSTAGEM", "ACAO_INCLUIR") && (
              <BtnIncluir url="/postagem/manter" />
            )}
            <BtnLimpar onClick={() => handleReset()} />
          </Col>
        </Card.Header>
        <Card.Body className="card-body">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Input
                md={4}
                label="Num. Cartão de Postagem"
                controlId="codigo_postal"
                placeholder="Num. Cartão de Postagem"
                onChange={e =>
                  (e.target.value = e.target.value.replace(/\D/g, ""))
                }
                register={register}
              />

              <Select
                md={4}
                label="Tipo Remetente"
                controlId="tipo_remetente"
                control={control}
                options={[
                  {
                    name: "<<Selecione>>",
                    value: ""
                  },
                  { name: "Funcionário", value: 1 },
                  { name: "Lotação", value: 2 }
                ]}
              />

              {_.get(remetente, 'value') == "1" && (
                <Select
                  md={4}
                  label="Funcionário"
                  controlId="remfuncionario"
                  control={control}
                  options={[
                    {
                      name: "<<Selecione>>",
                      value: ""
                    },
                    ...funcionarios
                  ]}
                />
              )}
              {_.get(remetente, 'value') == "2" && (
                <Select
                  md={4}
                  label="Lotação"
                  controlId="remlotacao"
                  control={control}
                  options={[
                    {
                      name: "<<Selecione>>",
                      value: ""
                    },
                    ...lotacoes
                  ]}
                />
              )}
            </Row>
            <Row>
              <Input
                md={3}
                label="Data Criação"
                type="date"
                controlId="data_criacao"
                placeholder="Data Criação"
                register={register}
              />
              <Input
                md={2}
                label="PLP"
                controlId="plp"
                placeholder="PLP"
                onChange={e =>
                  (e.target.value = e.target.value.replace(/\D/g, ""))
                }
                register={register}
              />
              <Select
                md={3}
                label="Destinatário"
                controlId="destinatario"
                control={control}
                options={[
                  { name: "<<Selecione>>", value: "" },
                  { name: "Lotação/Setor", value: 1 },
                  { name: "Funcionário", value: 2 },
                  { name: "Destinatario Externo", value: 3 }
                ]}
              />
              {_.get(destinatario, 'value') == "2" && (
                <Select
                  md={4}
                  label="Funcionário"
                  controlId="destfuncionario"
                  control={control}
                  options={[
                    {
                      name: "<<Selecione>>",
                      value: ""
                    },
                    ...destfuncionarios
                  ]}
                />
              )}
              {_.get(destinatario, 'value') == "1" && (
                <Select
                  md={4}
                  label="Lotação"
                  controlId="destlotacao"
                  control={control}
                  options={[
                    {
                      name: "<<Selecione>>",
                      value: ""
                    },
                    ...destlotacoes
                  ]}
                />
              )}
              {_.get(destinatario, 'value') == 3 && (
                <Select
                  md={4}
                  label="Destinatário Externo*"
                  controlId="destexterno"
                  control={control}
                  options={[
                    {
                      name: "<<Selecione>>",
                      value: ""
                    },
                    ...destexternos
                  ]}
                />
              )}
            </Row>
            <Row>
              <Select
                md={3}
                label="Situação"
                controlId="situacao"
                control={control}
                options={[
                  { name: "<<Selecione>>", value: "" },
                  { name: "Aberta", value: 1 },
                  { name: "Enviada", value: 2 }
                ]}
              />
              <BtnConsultar md={2} title="Consultar" />
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </Row>
  );
}

import React from "react";

import Button from "react-bootstrap/Button";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";

export function BtnAdicionarModal({ onClick, ...rest }) {
  return (
    <Button className="btn-add-modal" title="Visualizar" variant="success" onClick={onClick} {...rest}>
      <FontAwesomeIcon icon={faPlusCircle} />
    </Button>
  );
}

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";

import api from "../../../services/api";
import { hasAccess } from "../../../services/auth";

import {
  BtnLimpar,
  BtnConsultar,
  BtnIncluir
} from "../../../components/Button";
import { Input, Select } from "../../../components/Inputs";

function BuscarCartaoPostal({ onSubmit }) {
  const permissoes = useSelector(state => state.user.permissoes);

  const { register, handleSubmit, reset, control } = useForm();

  const [comarcas, setComarcas] = useState([]);
  const [lotacoes, setLotacoes] = useState([]);

  useEffect(() => {
    async function loadComarcas() {
      try {
        const comarcas = await api.get(`comarcas/listselect`);
        setComarcas(comarcas.data.data);
      } catch (err) {
        console.log(err);
      }
    }

    loadComarcas();
  }, []);

  const fetchLotacao = async data => {
    try {
      const response = await api.get(`lotacao/getbycomarca/${data}`);
      setLotacoes(response.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleComarcas = async ({ value }) => {
    value ? fetchLotacao(value) : setLotacoes([]);
  };

  function handleReset() {
    reset();
    onSubmit();
  }

  return (
    <>
      <div className="mt-4">
        <Row>
          <Col md={8}>
            <h4>Cadastrar Cartão Postal</h4>
          </Col>
          <Col md={4}>
            {hasAccess(permissoes, "SIGEP_CARTAO_POSTAGEM", "ACAO_INCLUIR") && (
              <BtnIncluir url="/cartaopostal/manter" />
            )}
            <BtnLimpar onClick={() => handleReset()} />
          </Col>
        </Row>
      </div>
      <Row>
        <hr />
        <Card className="card-cartao-postal">
          <Card.Header>
            <Col md={8}>
              <Card.Title>Consultar Cartão Postal</Card.Title>
            </Col>
            <Col md={4}></Col>
          </Card.Header>
          <Card.Body>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Input
                  md={8}
                  label="Código Cartão de Postagem"
                  controlId="codigo"
                  register={register}
                  placeholder="Código Cartão de Postagem"
                />
                <Input
                  md={4}
                  label="Data Vigência"
                  controlId="data_vigencia"
                  type="date"
                  register={register}
                  placeholder="Data Vigência"
                />
              </Row>
              <Row>
                <Select
                  md={4}
                  label="Comarca"
                  controlId="comarca"
                  onChangeSelect={handleComarcas}
                  control={control}
                  options={[{ name: "<<Selecione>>", value: "" }, ...comarcas]}
                />
                <Select
                  md={4}
                  label="Lotação/Setor"
                  controlId="lotacao_setor"
                  control={control}
                  options={[{ name: "<<Selecione>>", value: "" }, ...lotacoes]}
                />
                <Select
                  md={2}
                  label="Situação"
                  controlId="situacao"
                  control={control}
                  options={[
                    { name: "<<Selecione>>", value: "" },
                    { name: "Ativo", value: "Ativo" },
                    { name: "Inativo", value: "Inativo" }
                  ]}
                />
                <BtnConsultar type="submit" title="Consultar" />
              </Row>
            </Form>
          </Card.Body>
        </Card>
      </Row>
    </>
  );
}

export default BuscarCartaoPostal;

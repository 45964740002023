import React from "react";
import { Link } from "react-router-dom";

import Button from "react-bootstrap/Button";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'

export const BtnIncluir = ({url}) => (
    <div className="btn-top">
        <Link to={url}>
            <Button title="Incluir" className="float-right btn-top" variant="success">
                <FontAwesomeIcon icon={faPlusCircle} /> Incluir
            </Button>
        </Link>
    </div>
);

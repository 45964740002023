import React from "react";
import { Route, Redirect } from "react-router-dom";
import { isAuthenticated } from "../services/auth";
import { signInRequest } from "../store/modules/auth/actions";

import LoginLayout from "../pages/_layouts/LoginLayout";
import DefaultLayout from "../pages/_layouts/DefaultLayout";

import { hasAccess } from "../services/auth";

import { store } from "../store";

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      )
    }
  />
);

export default function RouteWrapper({
  component: Component,
  isPublic,
  permissionKey,
  action,
  ...rest
}) {
  const { signed } = store.getState().auth;
  const { permissoes } = store.getState().user;

  if (signed && isPublic) {
    return <Redirect to="/embalagens" />;
  }

  if (!signed) {
    store.dispatch(signInRequest());
    return;
  }

  if (permissionKey && action) {
    if (signed && permissoes && !hasAccess(permissoes, permissionKey, action)) {
      return <Redirect to="/sempermissao" />;
    }
  }

  const Layout = signed ? DefaultLayout : LoginLayout;

  return (
    <Route
      {...rest}
      render={props => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  );
}

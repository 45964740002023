import { takeLatest, call, put, all } from "redux-saga/effects";
import { toast } from "react-toastify";
import { store } from "../../../store";

import api from "../../../services/api";
import history from "../../../services/history";

import { signInSuccess, signFailure, signOut } from "./actions";

export function* signIn() {
  try {
    const value = "; " + document.cookie;
    const parts = value.split("; sso=");
    const cookie =
      parts.length == 2
        ? parts
            .pop()
            .split(";")
            .shift()
        : null;

    const response = yield call(api.post, "loginSSO", { sso: cookie });

    const { v_token, permissoes, v_sso, user } = response.data.data;

    if (v_token === null) {
      window.location.replace(v_sso);
      return;
    }

    api.defaults.headers["X-Token"] = v_token;

    yield put(signInSuccess(v_token, permissoes, user));

    history.push("/postagem");

    window.location.reload();
  } catch (err) {
    toast.error("Falha ao tentar autenticar. Tente novamente.");
    yield put(signFailure());
  }
}

export function setToken({ payload }) {
  if (!payload) return;

  const { token } = payload.auth;

  const value = "; " + document.cookie;
  const parts = value.split("; sso=");
  const cookie =
    parts.length == 2
      ? parts
          .pop()
          .split(";")
          .shift()
      : null;

  if (cookie === null) {
    store.dispatch(signOut());
    return;
  }

  if (token) {
    api.defaults.headers["X-Token"] = token;
  }
}

export function logOut() {
  document.cookie =
    "sso=; expires=Thu, 01-Jan-70 00:00:01 GMT;; domain=.mpma.mp.br; path=/";
  history.push("/");
}

export default all([
  takeLatest("persist/REHYDRATE", setToken),
  takeLatest("@auth/SIGN_IN_REQUEST", signIn),
  takeLatest("@auth/SIGN_OUT", signOut),
  takeLatest("@auth/SIGN_OUT", logOut)
]);

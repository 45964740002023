import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Table } from "../../components/Table";
import * as _ from 'lodash';

export default function ModalTable({
  show,
  setShow,
  title,
  objects,
  noDataMessage
}) {
  const columns = [
    {
      dataField: "observacoes",
      text: "Descrição",
      sort: true,
      formatter: (_, row) => {
        return row && row.observacoes && row.observacoes.replace(/&#34;/g, "\"").replace(/&#39;/g, "'");
      },
      style: { overflowWrap: 'break-word' }
    },
    {
      dataField: "destinatario",
      text: "Destinatário",
      sort: true,
      formatter: (cell, row) => {
        if (row.funcionario) {
          return row.funcionario.NOME;
        }

        if (row.lotacao) {
          return row.lotacao.comarca.NOME;
        }

        if (row.id_destinatario_externo_endereco) {
          return row.destinatario_externo_endereco.destinatario_externo.nome;
        }
      },
      style: { overflowWrap: 'break-word' }
    },
    {
      dataField: "tipo_embalagem",
      text: "Tipo Embalagem",
      sort: true,
      formatter: (cell, row) => row.embalagem.embalagem_tipo.descricao,
      style: { overflowWrap: 'break-word' }
    },
    {
      dataField: "peso",
      text: "Peso",
      sort: true,
      formatter: (cell, row) => row.embalagem.peso
    },
    {
      dataField: "comprimento",
      text: "Comprimento",
      sort: true,
      formatter: (cell, row) => row.embalagem.comprimento
    },
    {
      dataField: "largura",
      text: "Largura",
      sort: true,
      formatter: (cell, row) => row.embalagem.largura
    },
    {
      dataField: "altura",
      text: "Altura",
      sort: true,
      formatter: (cell, row) => row.embalagem.altura
    }
  ];

  return (
    <Modal
      show={show}
      size="xl"
      onHide={() => setShow(false)}
      animation={true}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body className="p-5">
        <Table data={objects} columns={columns} noDataMessage={noDataMessage} />
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShow(false)}>
          Fechar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

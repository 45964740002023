import React, { useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import {
  BtnSalvar,
  BtnVoltar,
  BtnEncerrar
} from "../../components/Button";

import api from "../../services/api";

import history from "../../services/history";

import Container from "react-bootstrap/Container";
import ManterPreListagem from "./ManterPreListagem";
import TabelaObjetoCadastradoPlp from "./TabelaObjetoCadastradoPlp";

export default function PreListagemManter() {
  let { id } = useParams();

  const [objetos, setObjetos] = useState([]);
  const [objenvio, setObjenvio] = useState([]);
  const [remetente, setRemetente] = useState([]);
  const [auth, setAuth] = useState(false);

  const [remove, setRemove] = useState(null);

  const handleRemove = useCallback(
    objetoIndex => {
      const filteredObjetos = objetos.filter(
        (obj, index) => objetoIndex !== index
      );
      const filteredObjetosEnvio = objenvio.filter(
        (obj, index) => objetoIndex !== index
      );
      setObjenvio(filteredObjetosEnvio);
      setObjetos(filteredObjetos);
    },
    [objetos, objenvio]
  );

  useEffect(() => {
    remove != null && handleRemove(remove);
    setRemove(null);
  }, [handleRemove, remove]);

  function submitObjetos() {
    let data = remetente;
    data["objetos"] = objenvio;
    id
      ? api
          .put(`/postagem/${id}`, data)
          .then(res => {
            toast.success("Atualizado a pré-listagem!");
          })
          .catch(error => {
            toast.error("Erro ao salvar pré-listagem!");
          })
      : api
          .post(`/postagem`, data)
          .then(res => {
            id = res.data.data.id;
            toast.success("Cadastrado a pré-listagem!");
          })
          .catch(error => {
            toast.error("Erro ao atualizar pré-listagem!");
          });
  }

  function submitEncerrar() {
    let data = remetente;
    data["objetos"] = objenvio;
    id
      ? api
          .put(`/postagem/${id}`, data)
          .then(res => {
            api
              .get(`/postagem/encerrar/${id}`)
              .then(r => {
                toast.success("Encerrado a pré-listagem!");
                history.push("/postagem");
              })
              .catch(error => {
                toast.error("Erro ao encerrar pré-listagem!");
              });
          })
          .catch(error => {
            toast.error("Erro ao encerrar pré-listagem!");
          })
      : api
          .post(`/postagem`, data)
          .then(res => {
            api
              .get(`/postagem/encerrar/${res.data.data["id"]}`)
              .then(r => {
                toast.success("Encerrado a pré-listagem!");
                history.push("/postagem");
              })
              .catch(error => {
                toast.error("Erro ao encerrar pré-listagem!");
              });
          })
          .catch(error => {
            toast.error("Erro ao encerrar pré-listagem!");
          });
  }

  return (
    <Container>
      <ManterPreListagem
        objetos={objetos}
        setObjetos={setObjetos}
        submitObjetos={submitObjetos}
        submitEncerrar={submitEncerrar}
        setRemetente={setRemetente}
        remetente={remetente}
        objenvio={objenvio}
        setObjenvio={setObjenvio}
        prelistagem_id={id}
        auth={auth}
        setAuth={setAuth}
      />
      <TabelaObjetoCadastradoPlp
        title="Objetos Cadastrados para a PLP"
        data={objetos}
        setRemove={setRemove}
        handleRemove={handleRemove}
        setObjetos={setObjetos}
        prelistagem_id={id}
      />
      <div className="mt-4 mb-5">
      <Row className="justify-content-md-center text-center mb-2">
            <BtnSalvar onClick={submitObjetos} />
            <BtnEncerrar
              onClick={() => {
                if (auth) {
                  submitEncerrar();
                } else {
                  toast.error("O cartão deve ser verificado!");
                }
              }}
            />
            <BtnVoltar />
        </Row>
      </div>
    </Container>
  );
}

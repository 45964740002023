import React from "react";

import Button from "react-bootstrap/Button";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";

export function BtnImprimir({ onClick, ...rest }) {
  return (
    <ButtonToolbar>
      <Button onClick={onClick} title="Imprimir" type="button" variant="info" {...rest}>
        <FontAwesomeIcon icon={faPrint} />
      </Button>
    </ButtonToolbar>
  );
}

import React, { useState, useEffect } from "react";

import Container from "react-bootstrap/Container";

import api from "../../services/api";

import { TabelaEmbalagens } from "./TabelaEmbalagens";
import { BuscarEmbalagens } from "./BuscarEmbalagens";
import { ConvertSelectObjectToValue } from "../../utils/convertSelectObjectToValue";

export default function EmbalagensConsultar() {
  const [embalagens, setEmbalagens] = useState([]);
  const [loading, setLoading] = useState(false);

  const onSubmit = data => {
    fetchEmbalagens(data);
  };

  const fetchEmbalagens = async data => {
    setLoading(true);
    
    if (data) {
      data.embalagem = ConvertSelectObjectToValue('embalagem', data);
      data.situacao = ConvertSelectObjectToValue('situacao', data);
    }

    api
      .get(`embalagem`, { params: data })
      .then(res => {

        const embalagens = res.data.data;
        const formattedResponse = embalagens.map((embalagem, index) => {
          embalagem.index = index + 1;
          return embalagem;
        });
        setEmbalagens(formattedResponse);
        setLoading(false);
      })
      .catch(error => {
        console.log(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchEmbalagens();
  }, []);

  return (
    <Container>
      <BuscarEmbalagens search={setEmbalagens} onSubmit={onSubmit} />
      <TabelaEmbalagens title="Consultar Embalagens" data={embalagens} loading={loading} />
    </Container>
  );
}

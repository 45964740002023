import axios from "axios";
import { getToken } from "./auth";
import { store } from "../store";
import { signOut } from "../store/modules/auth/actions";
import { ROOT_URL } from '../utils/Global';
import * as _ from 'lodash';

const api = axios.create({
  baseURL: `${ROOT_URL}`
});

api.interceptors.request.use(async config => {
  const token = getToken();
  if (token) {
    config.headers = { "X-Token": `${token}` };
  }
  return config;
});

api.interceptors.response.use(
  function (response) { return response; },
  function (error) {
    const codeError = _.get(error, 'response.status', 0);
    if (codeError === 401) {
      store.dispatch(signOut());
      window.location.reload()
      return Promise.resolve(_.get(error, 'response', 'Erro desconhecido'));
    } else {
      return Promise.reject(error);
    }
  }
);

export default api;

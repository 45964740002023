export const TOKEN_KEY = "@sigep-Token";
export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;
export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const login = token => {
  localStorage.setItem(TOKEN_KEY, token);
};
export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
};

export function hasAccess(userPermissions, key, action) {
  if (key && action) {
    if (userPermissions && userPermissions[key] && parseInt(userPermissions[key][action]) === 1) {
      return true;
    }
  }

  return false;
}

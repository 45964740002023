export function signInRequest() {
  return {
    type: '@auth/SIGN_IN_REQUEST',
    payload: { }
  };
}

export function signInSuccess(token, permissoes, user) {
  return {
    type: '@auth/SIGN_IN_SUCCESS',
    payload: { token, permissoes, user}
  };
}

export function signFailure() {
  return {
    type: '@auth/SIGN_FAILURE'
  };
}

export function signOut() {
  return {
    type: '@auth/SIGN_OUT'
  };
}

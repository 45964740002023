import React from "react";

import Button from "react-bootstrap/Button";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEraser } from "@fortawesome/free-solid-svg-icons";

export const BtnLimpar = ({ onClick }) => (
  <Button
    className="float-right btn-top"
    type="reset"
    title="Limpar"
    variant="info"
    onClick={onClick}
  >
    <FontAwesomeIcon icon={faEraser} /> Limpar
  </Button>
);

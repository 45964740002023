import React from "react";

import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

export const Input = ({
    md,
    controlId,
    register,
    placeholder,
    label,
    type,
    onChange,
    value,
    maxLength,
    max,
    min,
    disabled = "",
}) => (
    <Col md={md}>
        <Form.Group controlId={controlId}>
            <Form.Label>{label}</Form.Label>
            <Form.Control
                type={type || "text"}
                name={controlId}
                disabled={disabled}
                ref={register}
                max={max}
                min={min}
                maxLength={maxLength || 255}
                onChange={onChange}
                value={value}
                placeholder={placeholder}
            />
        </Form.Group>
    </Col>
);

import React from "react";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

import Container from "react-bootstrap/Container";

import history from "../../services/history";

import api from "../../services/api";

import { ManterEmbalagens } from "./ManterEmbalagens";
import { ConvertSelectObjectToValue } from "../../utils/convertSelectObjectToValue";

export default function EmbalagensManter({ modal, setShowemb }) {
  let { id } = useParams();
  id = modal ? false : id;

  const onSubmit = data => {
    fetchEmbalagens(data);
  };

  const fetchEmbalagens = async data => {

    ['id_embalagem_tipo', 'situacao'].forEach(field =>
      data[field] = ConvertSelectObjectToValue(field, data)
    );

    id
      ? api
        .put(`embalagem/${id}`, data)
        .then(res => {
          toast.success("Atualizado a embalagem!");
          history.push("/embalagens");
        })
        .catch(error => {
          console.log(error);
        })
      : api
        .post(`embalagem`, data)
        .then(res => {
          toast.success("Cadastrado a embalagem!");
          modal ? setShowemb() : history.push("/embalagens");
        })
        .catch(error => {
          console.log(error);
        });
  };

  return (
    <Container>
      <ManterEmbalagens onSubmit={onSubmit} embalagem_id={id} modal={modal} />
    </Container>
  );
}

import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Button from "react-bootstrap/Button";

import { hasAccess } from "../../services/auth";

import logo from "../../assets/img/logo.svg";

import { signOut } from "../../store/modules/auth/actions";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";

export default function Header() {
  const permissoes = useSelector(state => state.user.permissoes);
  const user = useSelector(state => state.user.user);

  const dispatch = useDispatch();

  function handleLogout() {
    dispatch(signOut());
  }

  return (
    <div className="header-sigep">
      <Container>
        <Navbar className="main-menu" expand="lg">
          <Navbar.Brand href="#home">
            <img
              src={logo}
              width="30"
              height="30"
              className="d-inline-block align-top"
              alt="React Bootstrap logo"
            />
          </Navbar.Brand>
          <Nav className="mr-auto">
            <NavDropdown title="Cadastro" id="basic-nav-dropdown">
              {hasAccess(permissoes, "SIGEP_EMBALAGENS", "ACAO_CONSULTAR") && (
                <Link className="dropdown-item" to="/embalagens">
                  Embalagem
                </Link>
              )}
              {hasAccess(
                permissoes,
                "SIGEP_DESTINATARIO_EXTERNO",
                "ACAO_CONSULTAR"
              ) && (
                <Link className="dropdown-item" to="/destinatarioexterno">
                  Destinatário Externo
                </Link>
              )}
            </NavDropdown>
            {hasAccess(permissoes, "SIGEP_POSTAGEM", "ACAO_CONSULTAR") && (
              <NavDropdown title="Pré-Lista" id="basic-nav-dropdown">
                <Link className="dropdown-item" to="/postagem">
                Cadastrar PLP
                </Link>
              </NavDropdown>
            )}
            {hasAccess(
              permissoes,
              "SIGEP_CARTAO_POSTAGEM",
              "ACAO_CONSULTAR"
            ) && (
              <NavDropdown title="Administração" id="basic-nav-dropdown">
                <Link className="dropdown-item" to="/cartaopostal">
                  Vincular Cartão de postagem
                </Link>
              </NavDropdown>
            )}
          </Nav>
          <Navbar.Collapse className="justify-content-end">
            <Navbar.Text className="user-name">
              {user}
            </Navbar.Text>
            <Button
              onClick={() => handleLogout()}
              className="btn-sair"
              style={{ background: "transparent", border: "0" }}
            >
              Sair <FontAwesomeIcon icon={faSignOutAlt} />
            </Button>
          </Navbar.Collapse>
        </Navbar>
      </Container>
    </div>
  );
}

import React from "react";

import Button from "react-bootstrap/Button";

import history from "../../services/history";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleLeft } from "@fortawesome/free-solid-svg-icons";

export function BtnVoltar({ url }) {
  const { goBack } = history;

  return (
    <Button
      title="Voltar"
      className="float-right btn-top"
      type="reset"
      variant="primary"
      onClick={goBack}
    >
      <FontAwesomeIcon icon={faArrowAltCircleLeft} /> Voltar
    </Button>
  );
}

import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { format, parseISO } from "date-fns";
import { toast } from "react-toastify";
import Container from "react-bootstrap/Container";

import api from "../../services/api";
import history from "../../services/history";

import { ManterCartaoPostal } from "./ManterCartaoPostal";
import { TabelaCartaoPostal } from "./TabelaCartaoPostal";
import { ConvertSelectObjectToValue } from "../../utils/convertSelectObjectToValue";

export default function CartaoPostalManter() {
  const [postal, setPostal] = useState("");
  const [postagem, setPostagem] = useState({
    situacao: "",
    contrato: "",
    vigencia: "",
    servicos: []
  });
  const [loading, setLoading] = useState(false);

  const { id } = useParams();

  const onSubmit = data => {
    postCartaoPostagem(data);
  };

  const postCartaoPostagem = async data => {
    data["servicos"] = postagem["servicos"];

    ['comarca'].forEach(field =>
      data[field] = ConvertSelectObjectToValue(field, data))

    id
      ? api
        .put(`cartao-postagem/${id}`, data)
        .then(res => {
          toast.success("Vinculo Cartão Postagem atualizado!");
          history.push("/cartaopostal");
        })
        .catch(error => {
          console.log(error.response.status);
          if (error.response.status === 409) {
            toast.error("Lotação já está vinculada a postagem, não é possível atualizar!");
          } else {
            toast.error("Não é possível atualizar o Cartão Postagem!");
          }

        })
      : api
        .post(`cartao-postagem`, data)
        .then(res => {
          toast.success("Vinculo Cartão Postagem cadastrado!");
          history.push("/cartaopostal");
        })
        .catch(error => {
          toast.error("Não é possível vincular o Cartão Postagem!");
        });
  };

  const fetchPostal = async data => {
    setLoading(true);
    api
      .get(`correios/getcliente`, { params: data })
      .then(res => {
        let data = res.data.data;
        if (!res.data.status && !id) {
          toast.error("Cartão Postagem já está vinculado!");
        } else {
          setPostagem({
            situacao: "Ativo",
            contrato: data["contrato"],
            servicos: data["servicos"].map(serv => {
              return {
                codserv: serv["codigo"],
                situacao: serv["situacao"],
                descricao: serv["descricao"]
              };
            }),
            vigencia: format(parseISO(data["data_vigencia"]), "dd/MM/yyyy")
          });
        }
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        toast.error("Cartão Postagem Inválido!");
      });
  };

  const handlePostagem = codigo => {
    setPostal(codigo.replace(/\D/g, ""));
    codigo.length === 10 && fetchPostal({ postal: codigo });
  };

  return (
    <Container>
      <ManterCartaoPostal
        handlePostagem={handlePostagem}
        postal={postal}
        postagem={postagem}
        onSubmit={onSubmit}
        cartao_id={id}
        setPostagem={setPostagem}
        setPostal={setPostal}
      />
      <TabelaCartaoPostal
        title="Serviços do Cartão de Postagem"
        data={postagem.servicos}
        loading={loading}
      />
    </Container>
  );
}

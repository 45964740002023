import React from "react";

import Spinner from "react-bootstrap/Spinner";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";

export const Table = ({
  title,
  data,
  columns,
  noDataMessage,
  loading = false
}) => {
  const customTotal = (from, to, size) => (
    <>
    {data.length ?
    (<span className="react-bootstrap-table-pagination-total">
      Mostrando {from} a {to} de {size} resultados
    </span>) : (<></>)
}
    </>
  );

  const options = {
    paginationSize: 10,
    pageStartIndex: 1,
    firstPageText: "Primeira",
    prePageText: "Voltar",
    nextPageText: "Avançar",
    lastPageText: "Última",
    nextPageTitle: "Primeira página",
    prePageTitle: "Página anterior",
    firstPageTitle: "Próxima página",
    lastPageTitle: "Último página",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10
      },
      {
        text: "20",
        value: 20
      },
      {
        text: "Todos",
        value: data.length || 10
      }
    ]
  };

  return (
    <>
      {loading ? (
        <div className="d-flex align-items-center justify-content-center">
          <Spinner animation="grow" variant="info" />
          <span>Carregando tabela...</span>
        </div>
      ) : (
        <>
          <div className="titulo">
            <h5 className="titulo-tabela">{title}</h5>
          </div>
          <BootstrapTable
            keyField="id"
            data={data}
            columns={columns}
            pagination={paginationFactory(options)}
            noDataIndication={
              noDataMessage
                ? noDataMessage
                : "Nenhum destinatário cadastrado no momento."
            }
          />
        </>
      )}
    </>
  );
};

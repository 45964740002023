import React from "react";

import { Table } from "../../../components/Table";

const columns = [
  {
    dataField: "rowIndex",
    text: "#",
    headerClasses: "tbl-id"
  },
  {
    dataField: "descricao",
    text: "Descrição",
    sort: true,
    headerClasses: "text-center"
  }
];

export const TabelaCartaoPostal = ({ title, data, loading }) => {
  return (
    <Table
      title={title}
      data={data}
      columns={columns}
      noDataMessage="Nenhum cartão postagem vinculado"
      loading={loading}
    />
  );
};

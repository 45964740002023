import React from "react";
import { Controller } from "react-hook-form";

import Select from "react-select";

import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";

export function SelectMulti({ md, controlId, control, options, label, loading }) {
  const styles = {
    multiValue: (base, state) => {
      return state.data.isFixed ? { ...base, backgroundColor: "gray" } : base;
    },
    multiValueLabel: (base, state) => {
      return state.data.isFixed
        ? { ...base, color: "white", paddingRight: 6 }
        : base;
    },
    multiValueRemove: (base, state) => {
      return state.data.isFixed ? { ...base, display: "none" } : base;
    }
  };

  return (
    <Col md={md}>
      <Form.Group controlId={controlId}>
        <Form.Label>{label}</Form.Label>
        <Controller
          as={
            <Select
              isLoading={loading}
              placeholder="Selecione"
              noOptionsMessage={() => "Sem opções"}
              closeMenuOnSelect={false}
              styles={styles}
              isClearable={false}
              isMulti
              options={options}
            />
          }
          name={controlId}
          control={control}
        />
      </Form.Group>
    </Col>
  );
}

import React, { useEffect, useState, useCallback } from "react";
import { useForm } from "react-hook-form";

import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";

import api from "../../../services/api";

import { Input, Select, SelectMulti } from "../../../components/Inputs";
import { BtnSalvar, BtnVoltar } from "../../../components/Button";

import * as _ from 'lodash';

function getUnique(arr, comp) {
  const unique = arr
    .map(e => e[comp])
    .map((e, i, final) => final.indexOf(e) == i && i)
    .filter(e => arr[e])
    .map(e => arr[e]);

  return unique;
}

export const ManterCartaoPostal = ({
  onSubmit,
  handlePostagem,
  postal,
  postagem,
  cartao_id,
  setPostagem,
  setPostal
}) => {
  const [comarcas, setComarcas] = useState([]);
  const [lotacoes, setLotacoes] = useState([]);
  const [loading, setLoading] = useState(false);

  const { register, handleSubmit, control, setValue, watch } = useForm({});

  const idComarca = watch("comarca");

  useEffect(() => {
    async function loadComarcas() {
      try {
        const response = await api.get(`comarcas/listselect`);

        setComarcas(response.data.data);
      } catch (err) {
        console.log(err);
      }
    }

    loadComarcas();
  }, []);

  useEffect(() => {
    async function loadCartao() {
      const response = await api.get(`cartao-postagem/${cartao_id}`);

      const data = response.data.data;

      setValue("comarca", data.id_comarca);

      setPostal(data.codigo);

      handlePostagem(data.codigo);

      const lotacoesCartao = data.cartao_postagem_vinculo.map(vinculo => ({
        label: vinculo.lotacao.DESCRICAO,
        value: parseInt(vinculo.lotacao.ID_LOTACAO_SETOR)
      }));

      const responseFixas = await api.get(
        `cartao-postagem-vinculo/lotacoesvinculo?cartao=${cartao_id}`
      );

      const formattedLotacoes = responseFixas.data.data.filter(
        lotacao => lotacao.name !== null && lotacao.value !== null
      );

      formattedLotacoes.forEach(lotacao => {
        lotacao.isFixed = true;
        lotacao.value = parseInt(lotacao.value);
      });

      const duplicatedLotacoes = [...formattedLotacoes, ...lotacoesCartao];

      const singleLotacoes = getUnique(duplicatedLotacoes, "value");

      console.log(singleLotacoes);
      setValue("lotacao", singleLotacoes);
    }

    cartao_id && loadCartao();
    //eslint-disable-next-line
  }, [cartao_id, setPostal, setValue]);

  const loadLotacoes = useCallback(async () => {
    setLoading(true);
    try {
      const response = await api.get(
        `cartao-postagem-vinculo/lotacoesnot?comarca=${_.get(idComarca, 'value')}`
      );

      const opcoes_lotacoes = response.data.data.map(lotacao => ({
        label: lotacao.name,
        value: lotacao.value
      }));

      setLotacoes([]);
      setLotacoes(oldLotacoes => [...oldLotacoes, ...opcoes_lotacoes]);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  }, [idComarca]);

  useEffect(() => {
    idComarca && loadLotacoes();
  }, [idComarca, loadLotacoes]);

  function handleSelectComarca() {
    setValue("lotacao", []);
    setLotacoes([]);
    loadLotacoes();
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Card className="card-destinatario">
        <Card.Header>
          <Col md={8}>
            <Card.Title>Manter Cartão Postagem</Card.Title>
            <Card.Subtitle className="text-muted" style={{ fontSize: 11 }}> * Campos obrigatórios</Card.Subtitle>
          </Col>
          <Col md={4}>
            <BtnSalvar type="submit" />
            <BtnVoltar />
          </Col>
        </Card.Header>
        <Card.Body className="card-body">
          <Row>
            <Input
              md={4}
              label="Código Cartão de Postagem*"
              controlId="codigo"
              name="codigo"
              onChange={e => handlePostagem(e.target.value)}
              value={postal}
              disabled={cartao_id ? "disabled" : ""}
              placeholder="Código Cartão de Postagem"
              register={register({ required: true })}
            />
            <Input
              md={2}
              label="Situação"
              controlId="situacao"
              disabled="disabled"
              value={postagem["situacao"]}
              register={register({ required: true })}
            />
            <Input
              md={2}
              label="Data Vigência"
              controlId="data_vigencia"
              disabled="disabled"
              value={postagem["vigencia"]}
              register={register({ required: true })}
            />
            <Input
              md={2}
              label="Contrato"
              controlId="contrato"
              disabled="disabled"
              value={postagem["contrato"]}
              register={register({ required: true })}
            />
          </Row>
          <Row>
            <Select
              md={4}
              label="Comarca*"
              controlId="comarca"
              onChange={handleSelectComarca}
              disabled={cartao_id ? "disabled" : ""}
              control={control}
              options={[{ name: "<<Selecione>>", value: "" }, ...comarcas]}
            />
          </Row>
          <Row>
            <SelectMulti
              md={12}
              label="Lotação"
              controlId="lotacao"
              loading={loading}
              control={control}
              options={lotacoes.length > 0 ? lotacoes : []}
            />
          </Row>
        </Card.Body>
      </Card>
    </Form>
  );
};

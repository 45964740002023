import React from "react";

import { Switch } from "react-router-dom";

import Route from "./Route";

import noMatch from "../components/noMatch";
import Login from "../pages/Login";
import EmbalagensManter from "../pages/EmbalagensManter";
import EmbalagensConsultar from "../pages/EmbalagensConsultar";
import DestinatarioExterno from "../pages/DestinatarioExternoConsultar";
import DestinatarioExternoManter from "../pages/DestinatarioExternoManter";
import CartaoPostalConsultar from "../pages/CartaoPostalConsultar";
import CartaoPostalManter from "../pages/CartaoPostalManter";
import PreListagemConsultar from "../pages/PreListagemConsultar";
import PreListagemManter from "../pages/PreListagemManter";
import SemPermissao from "../pages/SemPermissao";

// import DeclaracaoLayout from "../components/DocumentsLayouts/Declaracao";
// import PLPLayout from "../components/DocumentsLayouts/PLP";
// import RotuloLayout from "../components/DocumentsLayouts/Rotulo";
// import AvisoLayout from "../components/DocumentsLayouts/Aviso";

export default function Routes() {
  return (
    <Switch>
      <Route path="/login" exact component={Login} isPublic />
      <Route
        path="/destinatarioexterno"
        exact
        component={DestinatarioExterno}
        permissionKey="SIGEP_DESTINATARIO_EXTERNO"
        action="ACAO_CONSULTAR"
      />
      <Route
        path="/destinatarioexterno/manter"
        component={DestinatarioExternoManter}
        permissionKey="SIGEP_DESTINATARIO_EXTERNO"
        action="ACAO_INCLUIR"
      />
      <Route
        path="/destinatarioexterno/editar/:id"
        component={DestinatarioExternoManter}
        permissionKey="SIGEP_DESTINATARIO_EXTERNO"
        action="ACAO_ALTERAR"
      />
      <Route
        path="/embalagens"
        exact
        component={EmbalagensConsultar}
        permissionKey="SIGEP_EMBALAGENS"
        action="ACAO_CONSULTAR"
      />
      <Route
        path="/embalagens/manter"
        component={EmbalagensManter}
        permissionKey="SIGEP_EMBALAGENS"
        action="ACAO_INCLUIR"
      />
      <Route
        path="/embalagens/editar/:id"
        component={EmbalagensManter}
        permissionKey="SIGEP_EMBALAGENS"
        action="ACAO_ALTERAR"
      />
      <Route
        path="/cartaopostal"
        exact
        component={CartaoPostalConsultar}
        permissionKey="SIGEP_CARTAO_POSTAGEM"
        action="ACAO_CONSULTAR"
      />
      <Route
        path="/cartaopostal/manter"
        component={CartaoPostalManter}
        permissionKey="SIGEP_CARTAO_POSTAGEM"
        action="ACAO_INCLUIR"
      />
      <Route
        path="/cartaopostal/editar/:id"
        component={CartaoPostalManter}
        permissionKey="SIGEP_CARTAO_POSTAGEM"
        action="ACAO_ALTERAR"
      />
      <Route
        path="/postagem"
        exact
        component={PreListagemConsultar}
        permissionKey="SIGEP_POSTAGEM"
        action="ACAO_CONSULTAR"
      />
      <Route
        path="/postagem/manter"
        component={PreListagemManter}
        permissionKey="SIGEP_POSTAGEM"
        action="ACAO_INCLUIR"
      />
      <Route
        path="/postagem/editar/:id"
        component={PreListagemManter}
        permissionKey="SIGEP_POSTAGEM"
        action="ACAO_ALTERAR"
      />

      {/* <Route path="/imprimir/declaracao" component={DeclaracaoLayout} />
      <Route path="/imprimir/plp" component={PLPLayout} />
      <Route path="/imprimir/rotulo" component={RotuloLayout} />
      <Route path="/imprimir/aviso" component={AvisoLayout} /> */}

      <Route path="/sempermissao" exact component={SemPermissao} />
      <Route component={noMatch} />
    </Switch>
  );
}

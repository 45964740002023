import React from "react";

import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

export const BtnConsultar = ({md, type, onClick, title}) => (
    <Col md={md}>
        <ButtonToolbar>
            <Button className="btn-considerar" title={title} onClick={onClick} variant="primary" type={type || "submit"} block>
                <FontAwesomeIcon icon={faSearch} /> Consultar
            </Button>
        </ButtonToolbar>
    </Col>
);
import React from 'react'
import Container from 'react-bootstrap/Container'

export default function Footer() {
    return (
        <div className='footer'>
            <Container>
                <p className='d-flex justify-content-end text-white page-footer'>POSTAGENS - versão 1.0</p>
            </Container>
        </div>
    )
}

import React, { useState, useEffect } from "react";

import api from "../../services/api";
import * as _ from 'lodash';

import Container from "react-bootstrap/Container";

import BuscarDestinatarioExterno from "./BuscarDestinatarioExterno";
import TabelaDestinatarioExterno from "./TabelaDestinatarioExterno";

export default function DestinatarioExternoConsultar() {
  const [destinatarioExterno, setDestinatarioExterno] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchDestinatarioExterno = async data => {
    setLoading(true);
    
    if (data) data['situacao'] = _.get(data, 'situacao.value');

    await api
      .get(`destinatario-externo`, { params: data })
      .then(res => {
        const destinatarios = res.data.data;
        const formattedResponse = destinatarios.map((destinatario, index) => {
          destinatario.index = index + 1;
          return destinatario;
        });
        setDestinatarioExterno(formattedResponse);
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        console.log(error);
      });
  };

  const onSubmit = data => {
    fetchDestinatarioExterno(data);
  };

  useEffect(() => {
    fetchDestinatarioExterno();
  }, []);

  return (
    <Container>
      <BuscarDestinatarioExterno onSubmit={onSubmit} />
      <TabelaDestinatarioExterno
        title="Destinatário Externo"
        data={destinatarioExterno}
        loading={loading}
      />
    </Container>
  );
}

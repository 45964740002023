import React, { useState, useEffect } from "react";

import Container from "react-bootstrap/Container";

import api from "../../services/api";

import { TabelaCartaoPostal } from "./TabelaCartaoPostal";
import BuscarCartaoPostal from "./BuscarCartaoPostal";
import { ConvertSelectObjectToValue } from "../../utils/convertSelectObjectToValue";

export default function CartaoPostalConsultar() {
  const [postagem, setPostagem] = useState([]);
  const [loading, setLoading] = useState(false);

  const onSubmit = data => {
    fetchPostagens(data);
  };

  const fetchPostagens = async data => {
    setLoading(true);

    if (data) {
      ['comarca', 'lotacao_setor', 'situacao'].forEach(field =>
        data[field] = ConvertSelectObjectToValue(field, data)
      )
    }

    api
      .get(`cartao-postagem`, { params: data })
      .then(res => {
        const postagens = res.data.data;
        const formattedResponse = postagens.map((postagem, index) => {
          postagem.index = index + 1;
          return postagem;
        });
        setPostagem(formattedResponse);
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        console.log(error);
      });
  };

  useEffect(() => {
    fetchPostagens();
  }, []);

  return (
    <Container>
      <BuscarCartaoPostal search={setPostagem} onSubmit={onSubmit} />
      <TabelaCartaoPostal
        title="Consultar Cartão de Postagem"
        data={postagem}
        loading={loading}
      />
    </Container>
  );
}

import React from "react";
import { Link } from "react-router-dom";

export default function SemPermissao() {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "52px"
      }}
    >
      <span>Você não tem permissão para acessar essa página.</span>
    </div>
  );
}

import React, { useEffect } from "react";

import { useForm } from "react-hook-form";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";

import { Input, Select } from "../../../components/Inputs";

import api from "../../../services/api";

import { BtnSalvar, BtnVoltar } from "../../../components/Button";
import * as _ from 'lodash';

function ManterDestinatarioExterno({
  onSubmit,
  endereco,
  destinatario_id,
  modal
}) {
  const { register, watch, handleSubmit, reset, errors, control } = useForm({
    validateCriteriaMode: "all"
  });

  const person = watch("tipo_pessoa");

  useEffect(() => {
    async function loadDestinatario() {
      try {
        const response = await api.get(
          `destinatario-externo/${destinatario_id}`
        );
        reset(response.data.data);
      } catch (err) {
        console.log(err);
      }
    }

    destinatario_id && loadDestinatario();
  }, [destinatario_id, reset]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <input
        type={"hidden"}
        name="endereco"
        ref={register({ required: true })}
        value={endereco}
      />
      <div className="mt-4">
        <Row>
          <Col md={8}>
            <h4>Cadastrar Destinatário</h4>
            <Card.Subtitle className="text-muted" style={{ fontSize: 11 }}>* Campos obrigatórios</Card.Subtitle>
          </Col>
          <Col md={4}>
            <BtnSalvar type="submit" />
            {modal ? "" : <BtnVoltar />}
          </Col>
        </Row>
      </div>
      <hr />
      {errors.endereco && (
        <div style={{ color: "red" }}>
          {"Pelo menos um endereço deve ser adicionado."}
        </div>
      )}
      <Card className="my-4">
        <Card.Header>
          <Card.Title>Dados Gerais</Card.Title>
        </Card.Header>
        <Card.Body>
          <Row>
            <Select
              md={3}
              label="Tipo Pessoa"
              controlId="tipo_pessoa"
              control={control}
              options={[
                { name: "<<Selecione>>", value: "" },
                { name: "Física", value: "Física" },
                { name: "Jurídica", value: "Juridica" }
              ]}
            />
            {_.get(person, 'value') === "Física" && (
              <Input
                md={6}
                label="CPF"
                controlId="cnpj_cpf"
                placeholder="CPF"
                maxLength={11}
                register={register}
                onChange={e =>
                  (e.target.value = e.target.value.replace(/\D/g, ""))
                }
              />
            )}
            {_.get(person, 'value') === "Juridica" && (
              <Input
                md={6}
                label="CNPJ"
                controlId="cnpj_cpf"
                placeholder="CNPJ"
                maxLength={14}
                register={register}
                onChange={e =>
                  (e.target.value = e.target.value.replace(/\D/g, ""))
                }
              />
            )}

            {destinatario_id && (
              <Select
                md={2}
                label="Situação*"
                controlId="situacao"
                control={control}
                options={[
                  { name: "Ativo", value: "Ativo" },
                  { name: "Inativo", value: "Inativo" }
                ]}
              />
            )}
          </Row>

          <Row>
            <Select
              md={3}
              label={"Tratamento"}
              controlId="tratamento"
              control={control}
              options={[
                { name: "<<Selecione>>", value: "" },
                { name: "Senhor", value: "Sr." },
                { name: "Senhora", value: "Sra." },
                { name: "Senhorita", value: "Srta." },
                { name: "Doutor", value: "Dr." },
                { name: "Doutora", value: "Dra." }
              ]}
            />

            <Input
              md={8}
              label="Nome*"
              controlId="nome"
              placeholder="Nome"
              maxLength={255}
              register={register({ required: true })}
            />
          </Row>

        </Card.Body>
      </Card>

      <Card className="my-4">
        <Card.Header>
          <Card.Title>Contato</Card.Title>
        </Card.Header>
        <Card.Body>
          <Row>
            <Input
              md={4}
              label="Telefone*"
              controlId="telefone"
              placeholder="Telefone"
              maxLength={10}
              onChange={e => {
                e.target.value = e.target.value
                  .replace(/\D+/g, "")
                  .replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");
              }}
              register={register({
                required: true,
                pattern: /\(\d{2}\)\s\d{4,5}\-\d{4}/
              })}
            />
            <Input
              md={4}
              label="Celular"
              controlId="celular"
              placeholder="Celular"
              maxLength={11}
              onChange={e => {
                e.target.value = e.target.value
                  .replace(/\D+/g, "")
                  .replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
              }}
              register={register({ pattern: /\(\d{2}\)\s\d{4,5}\-\d{4}/ })}
            />
            <Input
              md={4}
              label="Fax"
              controlId="fax"
              placeholder="Fax"
              maxLength={10}
              onChange={e => {
                e.target.value = e.target.value
                  .replace(/\D+/g, "")
                  .replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");
              }}
              register={register({ pattern: /\(\d{2}\)\s\d{4,5}\-\d{4}/ })}
            />
          </Row>
          <Row>
            <Input
              md={6}
              label="E-mail"
              controlId="email"
              type="email"
              maxLength={255}
              placeholder="E-mail"
              register={register}
            />
            <Input
              md={6}
              label="Contato"
              controlId="contato"
              placeholder="Contato"
              maxLength={255}
              register={register}
            />
          </Row>
        </Card.Body>
      </Card>
    </Form>
  );
}

export default ManterDestinatarioExterno;

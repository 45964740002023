import React from "react";

import { useSelector } from "react-redux";

import history from "../../../services/history";
import { hasAccess } from "../../../services/auth";

import { Table } from "../../../components/Table";
import { BtnEditar } from "../../../components/Button";

function TabelaDestinatarioExterno({ title, data, loading }) {
  const permissoes = useSelector(state => state.user.permissoes);

  const columns = [
    {
      dataField: "index",
      text: "#",
      headerClasses: "tbl-id"
    },
    {
      dataField: "nome",
      text: "Nome",
      sort: true,
      headerClasses: "nome"
    },
    {
      dataField: "bairro",
      text: "Endereço",
      sort: true,
      headerClasses: "bairro"
    },
    {
      dataField: "cidade",
      text: "Cidade",
      sort: true,
      headerClasses: "cidade"
    },
    {
      dataField: "cep",
      text: "CEP",
      sort: true,
      headerClasses: "cep"
    },
    {
      dataField: "situacao",
      text: "Situação",
      sort: true,
      headerClasses: "situacao"
    },
    {
      dataField: "acoes",
      text: "Ação",
      formatter: (cell, row) =>
        hasAccess(permissoes, "SIGEP_DESTINATARIO_EXTERNO", "ACAO_ALTERAR") ? (
          <BtnEditar
            onClick={() =>
              history.push(`/destinatarioexterno/editar/${row.id}`)
            }
          />
        ) : '',
      headerClasses: "acoes"
    }
  ];

  return (
    <Table title={title} data={data} columns={columns} loading={loading} />
  );
}

export default TabelaDestinatarioExterno;

import React from "react";

import Button from "react-bootstrap/Button";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";

export function BtnEditar({ onClick, ...rest }) {
  return (
    <Button type="button" title="Editar" variant="danger" onClick={onClick} {...rest}>
      <FontAwesomeIcon icon={faEdit} />
    </Button>
  );
}

import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";

import { Input, Select } from "../../../components/Inputs";
import { BtnSalvar, BtnVoltar } from "../../../components/Button";
import api from "../../../services/api";
import * as _ from 'lodash';

export const ManterEmbalagens = ({ onSubmit, embalagem_id, modal }) => {
  const [tipoEmbalagem, setTipo] = useState([]);
  const { register, handleSubmit, reset, watch, control } = useForm({
    defaultValues: {}
  });

  const tipoEmbalagemObject = watch("id_embalagem_tipo");

  useEffect(() => {
    async function fetchData() {
      try {
        const responseEmbalagemTipo = await api.get(`embalagem-tipo`);
        setTipo(responseEmbalagemTipo.data.data);

        if (embalagem_id) {
          const responseEmbalagem = await api.get(`embalagem/${embalagem_id}`);
          reset(responseEmbalagem.data.data);
        }
      } catch (err) {
        console.log(err);
      }
    }
    fetchData();
  }, [embalagem_id, reset]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Card className="card-destinatario">
        <Card.Header>
          <Col md={8}>
            <Card.Title>Manter Embalagens</Card.Title>
            <Card.Subtitle className="text-muted" style={{ fontSize: 11 }}> * Campos obrigatórios</Card.Subtitle>
          </Col>
          <Col md={4}>
            <BtnSalvar type="submit" />
            {modal ? "" : <BtnVoltar />}
          </Col>
        </Card.Header>
        <Card.Body className="card-body">
          <Row>
            <Input
              md={7}
              label="Decrição*"
              controlId="descricao"
              placeholder="Descrição"
              register={register({ required: true })}
            />
            <Select
              md={3}
              label="Embalagem*"
              controlId="id_embalagem_tipo"
              control={control}
              options={[{ name: "<<Selecione>>", value: "" }, ...tipoEmbalagem]}
            />
            {embalagem_id && (
              <Select
                md={2}
                label="Situação*"
                controlId="situacao"
                control={control}
                options={[
                  { name: "Ativo", value: "Ativo" },
                  { name: "Inativo", value: "Inativo" }
                ]}
              />
            )}
          </Row>
          {watch("id_embalagem_tipo") && (
            <Row>
              {_.get(tipoEmbalagemObject, 'value') != "1" && (
                <Input
                  md={3}
                  label="Comprimento (cm)*"
                  controlId="comprimento"
                  placeholder="Comprimento (cm)"
                  onChange={e =>
                    (e.target.value = e.target.value.replace(/\D/g, ""))
                  }
                  register={register({ required: true })}
                />
              )}
              {_.get(tipoEmbalagemObject, 'value') == "2" && (
                <Input
                  md={3}
                  label="Largura (cm)*"
                  controlId="largura"
                  placeholder="Largura (cm)"
                  onChange={e =>
                    (e.target.value = e.target.value.replace(/\D/g, ""))
                  }
                  register={register({ required: true })}
                />
              )}
              {_.get(tipoEmbalagemObject, 'value') == "2" && (
                <Input
                  md={3}
                  label="Altura (cm)*"
                  controlId="altura"
                  placeholder="Altura (cm)"
                  onChange={e =>
                    (e.target.value = e.target.value.replace(/\D/g, ""))
                  }
                  register={register({ required: true })}
                />
              )}
              {_.get(tipoEmbalagemObject, 'value') == "3" && (
                <Input
                  md={3}
                  label="Diâmetro (cm)*"
                  controlId="diametro"
                  placeholder="Diâmetro (cm)"
                  onChange={e =>
                    (e.target.value = e.target.value.replace(/\D/g, ""))
                  }
                  register={register({ required: true })}
                />
              )}
              <Input
                md={3}
                label="Peso (gramas)*"
                controlId="peso"
                placeholder="Peso"
                onChange={e =>
                  (e.target.value = e.target.value.replace(/\D/g, ""))
                }
                register={register({ required: true })}
              />
            </Row>
          )}
        </Card.Body>
      </Card>
    </Form>
  );
};

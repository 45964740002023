import React, { useEffect } from "react";
import { element } from "prop-types";
import { getDate, getMonth, getYear } from "date-fns";
export default function Declaracao({ postagem }) {
  const month = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro"
  ];
  const date = new Date();

  return (
    <>
      {postagem.objeto_postagem &&
        postagem.objeto_postagem.map((element, index) => {
          return (
            <>
              <div class="print-container">
                <header className="header-declaracao">
                  <h2 className="title">DECLARAÇÃO DE CONTEÚDO</h2>
                </header>
                <div className="container-endereco">
                  <div className="remetente">
                    <div className="title">
                      <h3>REMETENTE</h3>
                    </div>
                    <div className="card-body">
                      <div className="info-declaracao card-nome">
                        <p>
                          <>Nome: </>{" "}
                          {postagem.funcionario
                            ? postagem.funcionario.NOME
                            : postagem.cartao_postagem_vinculo.lotacao_postagem
                              .DESCRICAO}
                        </p>
                      </div>
                      <div className="info-declaracao card-endereco">
                        <p>
                          <>ENDEREÇO: </>
                          {
                            postagem.cartao_postagem_vinculo.lotacao_postagem
                              .ENDERECO
                          }
                        </p>
                      </div>
                      <div className="info-declaracao card-cidade-uf">
                        <p className="card-cidade">
                          <>CIDADE: </>
                          {
                            postagem.cartao_postagem_vinculo.lotacao_postagem
                              .comarca.CIDADE
                          }
                        </p>
                        <p className="card-uf">
                          <>UF: </>
                          MA
                        </p>
                      </div>
                      <div className="info-declaracao card-cep-cnpj">
                        <p className="card-cep">
                          <>CEP: </>
                          {
                            postagem.cartao_postagem_vinculo.lotacao_postagem
                              .comarca.CEP
                          }
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="destinatario">
                    <div className="title">
                      <h3>DESTINATÁRIO</h3>
                    </div>
                    <div className="card-body">
                      <div className="info-declaracao card-nome">
                        <p>
                          <>Nome: </>
                          {element.id_funcionario
                            ? element.funcionario.NOME
                            : ""}
                          {element.id_destinatario_externo_endereco
                            ? element.destinatario_externo_endereco.destinatario_externo.nome
                            : ""}
                          {element.id_lotacao_setor
                            ? element.lotacao.DESCRICAO
                            : ""}
                        </p>
                      </div>
                      <div className="info-declaracao card-endereco">
                        <p>
                          <>ENDEREÇO: </>{" "}
                          {element.id_lotacao_setor
                            ? element.lotacao.ENDERECO
                            : ""}
                          {element.id_funcionario
                            ? element.funcionario.ENDERECO
                            : ""}
                          {element.id_destinatario_externo_endereco
                            ? element
                              .destinatario_externo_endereco.logradouro
                            : ""}
                        </p>
                      </div>
                      <div className="info-declaracao card-cidade-uf">
                        <p className="card-cidade">
                          <>CIDADE: </>
                          {element.id_destinatario_externo_endereco
                            ? element
                              .destinatario_externo_endereco.cidade
                            : ""}
                          {element.id_funcionario
                            ? element.funcionario.CIDADE
                            : ""}
                          {element.id_lotacao_setor
                            ? element.lotacao.comarca.CIDADE
                            : ""}
                        </p>
                        <p className="card-uf">
                          <>UF: </>
                          {element.id_destinatario_externo_endereco
                            ? element
                              .destinatario_externo_endereco.uf
                            : ""}
                          {element.id_funcionario ? "MA" : ""}
                          {element.id_lotacao_setor
                            ? "MA"
                            : ""}
                        </p>
                      </div>
                      <div className="info-declaracao card-cep-cnpj">
                        <p className="card-cep">
                          <>CEP: </>
                          {element.id_destinatario_externo_endereco
                            ? element
                              .destinatario_externo_endereco.cep
                            : ""}
                          {element.id_funcionario
                            ? element.funcionario.CEP
                            : ""}
                          {element.id_lotacao_setor ? element.lotacao.CEP : ""}
                        </p>
                        <p className="card-cnpj">
                          <>CNPJ/CPF: </>
                          {element.id_funcionario
                            ? element.funcionario.CPF
                            : ""}
                          {element.id_destinatario_externo_endereco
                            ? element.destinatario_externo_endereco.destinatario_externo.cnpj_cpf
                            : ""}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <h4>IDENTIFICAÇÃO DOS BENS</h4>
                <div className="body">
                  <p>{element.observacoes}</p>
                </div>
                <table className="">
                  <tbody>
                    <tr>
                      <td colspan="2" className="total">
                        PESO TOTAL(g)
                      </td>
                      <td>{element.embalagem.peso}</td>
                    </tr>
                  </tbody>
                </table>
                <div className="declaracao">
                  <div className="header">
                    <h4>DECLARAÇÃO</h4>
                  </div>
                  <div className="body">
                    <p style={{ textAlign: 'justify' }}>
                      Declaro que não me enquadro no conceito de contribuinte
                      previsto no art. 4° da Lei Complementar n° 87/1996, uma
                      vez que não realizo, com habilidade ou em volume que
                      caracterize intuito comercial, operações de circulação de
                      mercadoria, ainda que se iniciem no exterior, ou estou
                      dispensado da emissão da nota fiscal por força da
                      legislação tributária vigente, responsabilizando-me, nos
                      termos da lei e a quem de direito, por informações
                      inverídicas.
                    </p>
                    <p style={{ textAlign: 'justify' }}>
                      Declaro ainda que não estou postando conteúdo inflamável,
                      explosivo, causador de combustão espontânea, tóxico,
                      corrosivo, gás ou qualquer outro conteúdo que constitua
                      perigo, conforme o art. 13 da Lei Postal n° 6.538/78
                    </p>
                    <div className="data-assinatura">
                      <div className="data" style={{ backgroundColor: "rgb(215, 215, 215)" }}>
                        <p>
                          São Luís-MA,
                        </p>
                        <p>
                          {date.getDate()}
                        </p>
                        <p> de </p>
                        <p>
                          {month[date.getMonth()]}
                        </p>
                        <p> de </p>
                        <p>
                          {date.getFullYear()}
                        </p>
                      </div>
                      <>
                      </>
                      <div className="assinatura">
                        <p>Assinatura do Declarante/Remetente</p>
                      </div>
                    </div>
                  </div>
                </div>
                <footer>
                  <p>OBSERVAÇÃO:</p>
                  <p>
                    Constitui crime contra a ordem tributária suprimir ou
                    reduzir tributo, ou contribuição social e qualquer acessório
                    (Lei 8.137/90 Art. 1°, V)
                  </p>
                </footer>
              </div>
              {postagem.objeto_postagem.length - 1 > index ? (<div className="page-break" />) : ""}
            </>
          );
        })}
    </>
  );
}

import React, { useState, useEffect, useCallback } from "react";

import { useForm } from "react-hook-form";

import { Table } from "../../../components/Table";

import { Input, Select } from "../../../components/Inputs";
import {
  BtnConsultar,
  BtnAdicionar,
  BtnDeletar
} from "../../../components/Button";

import api from "../../../services/api";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import axios from "axios";

import { ConvertSelectObjectToValue } from "../../../utils/convertSelectObjectToValue";

function TabelaDestinatarioExternoEndereco({
  title,
  setEndereco,
  endereco,
  destinatario_id
}) {
  const {
    register,
    handleSubmit,
    setValue,
    errors,
    setError,
    clearError,
    getValues,
    reset,
    control
  } = useForm();

  const [remove, setRemove] = useState(null);

  const appendField = data => {
    if (endereco.length === 0) {
      data.endereco_principal = 1;
    }

    data.endereco_principal = ConvertSelectObjectToValue('endereco_principal', data);
    if (data.endereco_principal === 1) {
      endereco = endereco.map(e => {
        e.endereco_principal = 0;
        return e;
      });
    }

    setEndereco([...endereco, { ...data, index: endereco.length + 1 }]);
  };

  const removeField = useCallback(
    i => {
      setEndereco(endereco.filter((_, index) => index !== i));
    },
    [endereco, setEndereco]
  );

  useEffect(() => {
    remove != null && removeField(remove);
    setRemove(null);
  }, [remove, removeField]);

  useEffect(() => {
    async function loadEnderecos() {
      try {
        const response = await api.get(
          `destinatario-externo/${destinatario_id}`
        );

        reset(response.data.data.destinatario_externo_endereco);

        const enderecos = response.data.data.destinatario_externo_endereco.map(
          (endereco, index) => {
            return { ...endereco, index: index + 1 };
          }
        );

        setEndereco(enderecos);
      } catch (err) {
        console.log(err);
      }
    }

    destinatario_id && loadEnderecos();
  }, [destinatario_id, reset, setEndereco]);

  const columns = [
    {
      dataField: "index",
      text: "#",
      headerClasses: "tbl-id"
    },
    {
      dataField: "logradouro",
      text: "Logrado - Número",
      sort: true,
      headerClasses: "logradouro"
    },
    {
      dataField: "bairro",
      text: "Bairro",
      sort: true,
      headerClasses: "bairro"
    },
    {
      dataField: "cep",
      text: "CEP",
      sort: true,
      headerClasses: "cep"
    },
    {
      dataField: "acoes",
      text: "Ação",
      headerClasses: "acoes",
      formatter: (cell, row, rowIndex) => (
        <Row className="d-flex align-items-center justify-content-center">
          <BtnDeletar
            onClick={() => {
              setRemove(rowIndex);
            }}
          />
        </Row>
      )
    }
  ];

  const fetchCep = async e => {
    const cep = getValues()["cep"];
    cep.length === 8
      ? axios
        .get(`https://viacep.com.br/ws/${cep}/json/`)
        .then(res => {
          if (res.data.erro) {
            setError("cep", "notMatch", "CEP inválido");
          } else {
            clearError("cep");
            setValue("logradouro", res.data.logradouro);
            setValue("cidade", res.data.localidade);
            setValue("uf", res.data.uf);
            setValue("bairro", res.data.bairro);
          }
        })
        .catch(error => {
          console.log(error);
        })
      : reset();
  };

  const handleCep = e => {
    setValue("cep", e.target.value.replace(/\D/g, ""));
  };

  const onSubmit = (data, e) => {
    appendField(data);
    e.target.reset();
  };

  return (
    <Card className="my-4">
      <Card.Header>
        <Card.Title>Endereços</Card.Title>
      </Card.Header>
      <Card.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <input
            type={"hidden"}
            name="situacao"
            ref={register({ required: true })}
            value={"Ativo"}
          />
          <Row>
            <Input
              md={4}
              label="CEP*"
              controlId="cep"
              placeholder="CEP"
              maxLength={8}
              onChange={handleCep}
              register={register({ required: true })}
            />
            <Col md={3}>
              <BtnConsultar
                onClick={fetchCep}
                type="button"
                title="Consultar"
              />
            </Col>
          </Row>
          {errors.cep && (
            <div style={{ color: "red" }}>{errors.cep.message}</div>
          )}
          <Row>
            <Input
              md={10}
              label="Logradouro*"
              controlId="logradouro"
              placeholder="Logradouro"
              register={register({ required: true })}
            />
            <Input
              md={2}
              label="Numero*"
              controlId="numero"
              placeholder="Numero"
              register={register({ required: true })}
            />
          </Row>
          <Row>
            <Input
              md={8}
              label="Complemento"
              controlId="complemento"
              placeholder="Complemento"
              register={register}
            />
            <Input
              md={4}
              label="Bairro*"
              controlId="bairro"
              placeholder="Bairro"
              register={register({ required: true })}
            />
          </Row>
          <Row>
            <Input
              md={5}
              label="Cidade*"
              disabled="disabled"
              controlId="cidade"
              register={register({ required: true })}
            />
            <Input
              md={2}
              label="UF*"
              disabled="disabled"
              controlId="uf"
              register={register({ required: true })}
            />
            {endereco.length > 0 && (
              <Select
                md={2}
                label="End. Principal*"
                controlId="endereco_principal"
                control={control}
                options={[
                  { name: "Não", value: 0 },
                  { name: "Sim", value: 1 }
                ]}
              />
            )}
            <BtnAdicionar
              title="Adicionar Endereço"
              text="Adicionar"
              type={"submit"}
            ></BtnAdicionar>
          </Row>
        </Form>
        <Table
          title={title}
          data={endereco}
          columns={columns}
          noDataMessage="Nenhum endereço cadastrado"
        />
      </Card.Body>
    </Card>
  );
}

export default TabelaDestinatarioExternoEndereco;

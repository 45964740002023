import React from "react";
import api from "../../services/api";

const noMatch = ({ location }) => {
  api.get(`/`);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "52px"
      }}
    >
      <span>Página não encontrada</span>
    </div>
  );
};

export default noMatch;

import React, { useEffect } from "react";

import { Controller } from "react-hook-form";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import ReactSelect from "react-select";
import * as _ from 'lodash';


export const mapObjectToSelectObject = ({ value, name }) => {
    return { value, label: name }
}
export const Select = ({ md, controlId, control, options, label, onChangeSelect, loading, value }) => {
    const _options = options.map(option => mapObjectToSelectObject(option));

    const _value = _.get(control, `defaultValuesRef.current.${controlId}`);
    useEffect(() => {
        const currentOption = _options.find(opt => opt.value === _value);
        if (currentOption) {
            control.setValue(controlId, currentOption)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value])

    return (
        <Col md={md}>
            <Form.Group controlId={controlId}>
                <Form.Label>{label}</Form.Label>
                <Controller
                    as={<ReactSelect />}
                    defaultValue={value || _options[0]}
                    isLoading={loading}
                    placeholder="Selecione"
                    noOptionsMessage={() => "Sem opções"}
                    isClearable
                    isSearchable
                    options={_options}
                    onChange={([selected]) => {
                        if (selected)
                            onChangeSelect && onChangeSelect(selected)
                        return { value: selected || {} };
                    }}
                    name={controlId}
                    control={control}

                />
            </Form.Group>
        </Col>
    );
}

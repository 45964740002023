import React, { useState, useEffect } from "react";

import api from "../../services/api";

import Container from "react-bootstrap/Container";

import BuscarPreListagem from "./BuscarPreListagem";
import TabelaPreListagem from "./TabelaPreListagem";
import { ConvertSelectObjectToValue } from "../../utils/convertSelectObjectToValue";

export default function PreListagemConsultar() {
  const [preListagem, setprPListagem] = useState([]);
  const [loading, setLoading] = useState(false);

  const onSubmit = data => {
    fetchPreListagem(data);
  };

  const fetchPreListagem = async data => {
    setLoading(true);
    if (data) {
      [
        'tipo_remetente', 'remfuncionario',
        'remlotacao', 'destinatario', 'destlotacao',
        'destexterno', 'situacao'
      ].forEach(field => data[field] = ConvertSelectObjectToValue(field, data))
    }
    api
      .get(`postagem`, { params: data })
      .then(res => {
        const preListagens = res.data.data;
        const formattedResponse = preListagens.map((preListagem, index) => {
          preListagem.index = index + 1;
          preListagem.remetente =
            preListagem.remetente_func || preListagem.remetente_lotac;
          return preListagem;
        });
        setprPListagem(formattedResponse);
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        console.log(error);
      });
  };

  useEffect(() => {
    fetchPreListagem();
  }, []);

  return (
    <Container>
      <BuscarPreListagem onSubmit={onSubmit} />
      <TabelaPreListagem
        title="Pré Listagem"
        data={preListagem}
        fetchPreListagem={fetchPreListagem}
        loading={loading}
      />
    </Container>
  );
}

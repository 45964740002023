import React from "react";
import { useSelector } from "react-redux";
import { format, parseISO } from "date-fns";

import history from "../../../services/history";
import { hasAccess } from "../../../services/auth";

import { Table } from "../../../components/Table";
import { BtnEditar } from "../../../components/Button";

export const TabelaCartaoPostal = ({ title, data, loading }) => {
  const permissoes = useSelector(state => state.user.permissoes);

  const columns = [
    {
      dataField: "index",
      text: "#",
      headerClasses: "tbl-id"
    },
    {
      dataField: "codigo",
      text: "Código",
      sort: true,
      headerClasses: "codigo"
    },
    {
      dataField: "comarca",
      text: "Comarca",
      sort: true,
      headerClasses: "comarca"
    },
    {
      dataField: "data_vigencia",
      text: "Data Vigência",
      sort: true,
      formatter: (cell, row) =>
        format(parseISO(row.data_vigencia), "dd/MM/yyyy"),
      headerClasses: "datavigencia"
    },
    {
      dataField: "situacao",
      text: "Situação",
      sort: true,
      headerClasses: "situacao"
    },
    {
      dataField: "acoes",
      text: "Ação",
      formatter: (cell, row) =>
        hasAccess(permissoes, "SIGEP_CARTAO_POSTAGEM", "ACAO_ALTERAR") ? (
          <BtnEditar
            onClick={() => history.push(`/cartaopostal/editar/${row.id}`)}
          />
        ) : '',
      headerClasses: "destinatario-acoes"
    }
  ];

  return (
    <Table
      title={title}
      data={data}
      columns={columns}
      loading={loading}
      noDataMessage="Nenhum cartão postagem vinculado"
    />
  );
};

import React from "react";

import Button from "react-bootstrap/Button";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

export const BtnDeletar = ({ onClick, ...rest }) => (
  <ButtonToolbar>
    <Button title="Excluir" onClick={onClick} type="button" variant="danger" {...rest}>
      <FontAwesomeIcon icon={faTrash} />
    </Button>
  </ButtonToolbar>
);

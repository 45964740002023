import React from "react";
import { Link } from "react-router-dom";

import Button from "react-bootstrap/Button";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faListAlt } from "@fortawesome/free-solid-svg-icons";

export const BtnEncerrar = ({ onClick, ...rest }) => (
    <Button
        title="Encerrar"
        className="float-right btn-top"
        variant="info"
        title="Fechar"
        onClick={onClick}
        {...rest}
    >
        <FontAwesomeIcon icon={faListAlt} /> FECHAR
    </Button>
);

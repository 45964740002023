import React from 'react';
import Modal from "react-bootstrap/Modal";
import Button from 'react-bootstrap/Button';

export default function Popup({ show, setShow, onSave, title, children }) {
  return (
    <Modal show={show} onHide={() => setShow(false)} animation={true} centered>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {children}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>Cancelar</Button>
          <Button variant="primary" onClick={onSave}>Validar</Button>
        </Modal.Footer>
    </Modal>
  );
}

import React from "react";

import Button from "react-bootstrap/Button";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

export const BtnSalvar = ({ onClick, ...rest }) => (
  <Button className="float-right btn-top" title="Salvar" variant="success" onClick={onClick} {...rest}>
    <FontAwesomeIcon icon={faCheckCircle} /> Salvar
  </Button>
);

import React, { useState } from "react";

import { useSelector } from "react-redux";

import { hasAccess } from "../../../services/auth";

import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import history from "../../../services/history";
import api from "../../../services/api";
import { format, parseISO } from "date-fns";
import { toast } from "react-toastify";

import { Table } from "../../../components/Table";
import {
  BtnEditar,
  BtnAdicionarModal,
  BtnImprimir,
  BtnDeletar
} from "../../../components/Button";

import ModalTable from "../../../components/ModalTable";
import PrintingModal from "../../../components/PrintingModal";

function TabelaPreListagem({ title, data, fetchPreListagem, loading }) {
  const permissoes = useSelector(state => state.user.permissoes);

  const [objetcsModalShow, setObjetcsModalShow] = useState(false);
  const [printingModalShow, setPrintingModalShow] = useState(false);
  const [remove, setRemove] = useState(false);
  const [removeModalShow, setRemoveModalShow] = useState(false);

  const [postagem, setPostagem] = useState({});

  async function handleObjectsModalShow(id) {
    try {
      const response = await api.get(`postagem/${id}`);

      setPostagem(response.data.data);
      setObjetcsModalShow(true);
      setRemove(false);
    } catch (err) {
      console.log(err);
    }
  }

  function handlePrintingModalShow(row) {
    setPostagem(row);
    setPrintingModalShow(true);
  }

  async function handleDeletePostagem() {
    try {
      const response = await api.delete(`postagem/${remove}`);
      toast.success("Deletado a postagem!");
      fetchPreListagem();
      setRemoveModalShow(false);
      setRemove(false);
    } catch (err) {
      toast.error("Não foi possível deletar a postagem!");
      console.log(err);
    }
  }

  const columns = [
    {
      dataField: "index",
      text: "#",
      headerStyle: {
        width: "7%"
      },
      style: { overflowWrap: "break-word" }
    },
    {
      dataField: "num_pedido",
      text: "Número PLP",
      sort: true,
      headerStyle: {
        width: "12.5%"
      },
      style: { overflowWrap: "break-word" }
    },
    {
      dataField: "remetente",
      text: "Remetente",
      sort: true,
      headerStyle: {
        width: "30%"
      },
      style: { overflowWrap: "break-word" }
    },
    {
      dataField: "criado_em",
      text: "Data Criação",
      sort: true,
      headerStyle: {
        width: "11%"
      },
      formatter: (cell, row) => format(parseISO(row.criado_em), "dd/MM/yyyy"),
      style: { overflowWrap: "break-word" }
    },
    {
      dataField: "total",
      text: "Qtd. Objetos",
      headerStyle: {
        width: "7%"
      },
      style: { overflowWrap: "break-word" }
    },
    {
      dataField: "usuario_responsavel",
      text: "Usuário Criador",
      sort: true,
      headerStyle: {
        width: "12.5%"
      },
      style: { overflowWrap: "break-word" }
    },
    {
      dataField: "situacao",
      text: "Situação",
      sort: true,
      headerStyle: {
        width: "9%"
      },
      style: { overflowWrap: "break-word" },
      formatter: (cell, row) => {
        return row.num_pedido ? <>Enviada</> : <>Aberta </>;
      }
    },
    {
      dataField: "acoes",
      text: "Ação",
      formatter: (cell, row) => (
        <Row className="d-flex align-items-center justify-content-around">
          {!row.num_pedido &&
          hasAccess(permissoes, "SIGEP_POSTAGEM", "ACAO_ALTERAR") ? (
            <BtnEditar
              className="m-0 mb-1"
              size="sm"
              onClick={() => history.push(`/postagem/editar/${row.id}`)}
            />
          ) : (
            ""
          )}
          <BtnAdicionarModal
            className="m-0 mb-1"
            size="sm"
            onClick={() => handleObjectsModalShow(row.id)}
          />
          {row.num_pedido && (
            <BtnImprimir
              className="m-0 mb-1"
              size="sm"
              onClick={() => handlePrintingModalShow(row)}
            />
          )}
          {!row.num_pedido &&
            (hasAccess(permissoes, "SIGEP_POSTAGEM", "ACAO_EXCLUIR") ? (
              <BtnDeletar
                className="m-0 mb-1"
                size="sm"
                onClick={() => {
                  setRemoveModalShow(true);
                  setRemove(row.id);
                }}
              />
            ) : (
              ""
            ))}
        </Row>
      ),
      headerClasses: "acoes"
    }
  ];

  return (
    <>
      <Table
        title={title}
        data={data}
        columns={columns}
        noDataMessage="Nenhum objeto encontrado"
        loading={loading}
      />
      <ModalTable
        show={objetcsModalShow}
        setShow={setObjetcsModalShow}
        title="Objetos da postagem"
        noDataMessage="Nenhum objeto cadastrado"
        objects={(postagem && postagem.objeto_postagem) || []}
      />
      <Modal
        show={removeModalShow}
        onHide={() => setRemoveModalShow(false)}
        animation={true}
        centered
      >
        <Modal.Body>Realmente Deseja Excluir?</Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => setRemoveModalShow(false)}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleDeletePostagem}>
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>
      <PrintingModal
        show={printingModalShow}
        postagem={postagem}
        setShow={setPrintingModalShow}
        title="Imprimir"
      />
    </>
  );
}

export default TabelaPreListagem;

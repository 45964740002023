import { Table } from "../../../components/Table";
import { BtnEditar } from "../../../components/Button";

import { useSelector } from "react-redux";

import history from "../../../services/history";
import { hasAccess } from "../../../services/auth";

import React from "react";

export const TabelaEmbalagens = ({ title, data, loading }) => {
  const permissoes = useSelector(state => state.user.permissoes);

  const columns = [
    {
      dataField: "index",
      text: "#",
      headerClasses: "tbl-id"
    },
    {
      dataField: "descricao",
      text: "Descrição",
      sort: true,
      headerClasses: "descricao"
    },
    {
      dataField: "comprimento",
      text: "Comprimento",
      sort: true,
      headerClasses: "comprimento"
    },
    {
      dataField: "largura",
      text: "Largura",
      sort: true,
      headerClasses: "largura"
    },
    {
      dataField: "altura",
      text: "Altura",
      sort: true,
      headerClasses: "altura"
    },
    {
      dataField: "peso",
      text: "Peso",
      sort: true,
      headerClasses: "peso"
    },
    {
      dataField: "acoes",
      text: "Ação",
      formatter: (cell, row) =>
        hasAccess(permissoes, "SIGEP_EMBALAGENS", "ACAO_ALTERAR") ? (
          <BtnEditar
            onClick={() => history.push(`/embalagens/editar/${row.id}`)}
          />
        ) : '',
      headerClasses: "destinatario-acoes"
    }
  ];

  return (
    <Table
      title={title}
      data={data}
      columns={columns}
      noDataMessage="Nenhuma embalagem cadastrada no momento"
      loading={loading}
    />
  );
};

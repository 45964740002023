import React from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";

import { Input } from "../../components/Inputs/Input";

import { signInRequest } from "../../store/modules/auth/actions";

export default function Login() {
  const dispatch = useDispatch();

  const { handleSubmit, register } = useForm();

  function onSubmit(data) {
    const { usuario, senha } = data;
    
    dispatch(signInRequest(usuario, senha));
  }

  return (
    <Container
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh"
      }}
    >
      <Card style={{ width: "24rem" }}>
        <Card.Header className="text-center">
          <Card.Title>Sigep</Card.Title>
        </Card.Header>
        <Card.Body>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Input
              label="Usuário"
              controlId="usuario"
              placeholder="Seu usuário"
              register={register}
            />
            <Input
              label="Senha"
              controlId="senha"
              placeholder="Sua senha"
              register={register}
            />
            <Row className="d-flex justify-content-center mt-3">
              <Button variant="primary" type="submit">
                Entrar
              </Button>
            </Row>
          </form>
        </Card.Body>
      </Card>
    </Container>
  );
}
